import React, { useState } from 'react';
import { Container } from '../Screen6/Screen6.styles';
import { ReactComponent as Screen29Svg } from './../../assets/images/svg/Screen29.svg';
import { ReactComponent as Screen292Svg } from './../../assets/images/svg/Screen29-2.svg';
import { Popup } from '../../components/Popup/Popup';
import { Graphic } from '../../components/Graphic/Graphic';
import { IScreen } from '../../storage/types/app.types';
import { TitleStyled } from '../main.page.styles';
import { Slider } from '../../components/Slider/Slider';
import { Info } from '../../components/Popup/Popup.styles';

const Screen29: React.FC<IScreen> = ({ modalActive, setModalActive }) => {
    const [activeInfo, setActiveInfo] = useState<number>(0);

    const Slide1 = (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}
        >
            <TitleStyled
                style={{
                    fontSize: '30px',
                    lineHeight: '34px',
                    width: '74%',
                }}
            >
                Противовирусная терапия ХГС у больных с декомпенсированным
                циррозом печени, находящихся в Листе ожидания трансплантации
                печени
            </TitleStyled>
            <Screen29Svg style={{ width: '90%' }} />
        </div>
    );
    const Slide2 = (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}
        >
            <TitleStyled
                style={{
                    width: '60%',
                    fontSize: '36px',
                    lineHeight: '40px',
                    marginBottom: '40px',
                }}
            >
                Алгоритм ведения больных с декомпенсированным ЦП
            </TitleStyled>
            <Screen292Svg style={{ width: '80%' }} />
        </div>
    );

    const Info1 = (
        <Info>
            <h2>Источники и сокращения</h2>
            <p>
                1. ВГС — вирус гепатита С;
                <br />
                <br />
                2. ПВТ — противовирусная терапия;
                <br />
                <br />
                3. ХГС — хронический гепатит С;
                <br />
                <br />
                4. ЦП — цирроз печени;
                <br />
                <br />
                5. ОТП — ортотопическая трансплантация печени;
                <br />
                <br />
                6. Клинические рекомендации МЗ РФ по ХВГС,{' '}
                <span>2022 г. КР 516</span>
                <br />
                <br />
                7. Лист ожидания трансплантации печени - формулируемый каждым
                трансплантационным центром динамически обновляемый регистр
                пациентов, нуждающихся в трансплантации печени, прошедших полное
                обследование по принятой в центре программы, не имеющих
                противопоказания к операции, давших письменное информированное
                согласие на ее проведение. Методика формирования и ведения
                «Листа ожидания» трансплантации печени. <br />
                <span>
                    Хубутия М.Ш. Журнал "Трансплантология", выпуск №1, 2009
                </span>
            </p>
        </Info>
    );

    const Info2 = (
        <Info>
            <h2>Источники и сокращения</h2>
            <p>
                1. ВГВ – вирус гепатита В;
                <br />
                <br />
                2. ВГС – вирус гепатита С;
                <br />
                <br />
                3. ПВТ – противовирусная терапия;
                <br />
                <br />
                4. ХГВ – хронический гепатит В;
                <br />
                <br />
                5. ХГС – хронический гепатит С;
                <br />
                <br />
                6. ЦП – цирроз печени;
                <br />
                <br />
                7. Шкала MELD - модель для оценки терминальной стадии
                заболеваний печени (предназначена для оценки относительной
                тяжести заболевания и прогноза жизни у пациентов с терминальной
                стадией печеночной недостаточности, ожидающих трансплантации
                печени;
                <br />
                <br />
                8. Алгоритм составлен на основании рекомендаций, представленных
                в клинических рекомендациях МЗ РФ по хроническому вирусному
                гепатиту С, 2022 г.
            </p>
        </Info>
    );

    const updateInfo = (slide: number) => {
        setActiveInfo(slide);
    };

    const infoContent: JSX.Element[] = [Info1, Info2];

    const sliderContent: JSX.Element[] = [Slide1, Slide2];

    return (
        <Container style={{ zIndex: 8000, overflow: 'scroll' }}>
            {modalActive
                ? infoContent[activeInfo] && (
                      <Popup active={modalActive} setActive={setModalActive}>
                          {infoContent[activeInfo]}
                      </Popup>
                  )
                : null}

            <Graphic
                marginTop={`${0}`}
                style={{
                    overflow: 'scroll',
                }}
            >
                <Slider
                    content={sliderContent}
                    updateInfo={updateInfo}
                    startingSlide={0}
                />
            </Graphic>
        </Container>
    );
};

export default Screen29;
