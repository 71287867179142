import React, { useState } from 'react';
import { Slider } from '../../components/Slider/Slider';
import { ReactComponent as Profiles } from './../../assets/images/svg/Profiles.svg';
import Slide2Svg from './../../assets/images/svg/Slide16-2.png';
import { ReactComponent as Slide3Svg } from './../../assets/images/svg/Slide16-3.svg';
import { Container } from '../Screen6/Screen6.styles';
import { Info } from '../../components/Popup/Popup.styles';
import { Popup } from '../../components/Popup/Popup';
import { IScreen } from '../../storage/types/app.types';
import { Graphic } from '../../components/Graphic/Graphic';

export const Screen16: React.FC<IScreen> = ({
    modalActive,
    setModalActive,
}) => {
    const [activeInfo, setActiveInfo] = useState<number>(0);

    const Slide1 = (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            }}
        >
            <h2
                style={{
                    fontFamily: 'Seravek, sans-serif',
                    justifySelf: 'center',
                    alignSelf: 'center',
                    fontWeight: 700,
                    fontSize: 42,
                    lineHeight: '26px',
                    textAlign: 'center',
                    color: 'var(--blue)',
                    marginTop: '-60px',
                }}
            >
                Вемлиди®. Профили пациентов.
            </h2>
            <Profiles style={{ width: '75vw', marginTop: 50 }} />
        </div>
    );
    const Slide2 = (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}
        >
            <img src={Slide2Svg} style={{ width: '80vw', marginTop: '20px' }} />
            <Slide3Svg style={{ width: '80vw', marginTop: '-60px' }} />
        </div>
    );

    const Info1 = (
        <Info style={{ paddingTop: 0, paddingBottom: 0 }}>
            <h2 style={{ fontSize: 24 }}>Источники</h2>
            <ol type="1">
                <li>
                    <span>
                        https://grls.rosminzdrav.ru/Grls_View_v2.aspx?routingGuid=73e28aae-5de5-48c7-89b5-3a82fc33f95d&t=
                    </span>
                    Инструкция по медицинскому применению препарата Вемлиди®
                    ЛП-005643
                </li>
                <li>EASL CPG HBV. J Hepatol 2017;67:370–98</li>
                <li>
                    *Актуально при невозможности назначения Биктарви ® /Генвоя®
                </li>
                <li>ТДФ — тенофовира дизопроксила фумарат</li>
                <li>ЛАМ — ламивудин</li>
                <li>АН — аналоги нуклеотидов</li>
                <li>МПК — минеральная плотность костей</li>
                <li>ХГВ — хронический гепатит В</li>
                <li>ЭТВ — энтекавир</li>
                <li>ИМП - инструкция по медицинскому применению</li>
            </ol>
        </Info>
    );

    const updateInfo = (slide: number) => {
        setActiveInfo(slide);
    };

    const sliderContent: JSX.Element[] = [Slide1, Slide2];
    const infoContent: JSX.Element[] = [Info1];

    return (
        <Container style={{ zIndex: 8000, overflow: 'auto' }}>
            {modalActive
                ? infoContent[activeInfo] && (
                      <Popup active={modalActive} setActive={setModalActive}>
                          {infoContent[activeInfo]}
                      </Popup>
                  )
                : null}

            <Graphic marginTop={`${0}`} style={{ overflow: 'auto' }}>
                <div>
                    <Slider
                        content={sliderContent}
                        updateInfo={updateInfo}
                        startingSlide={0}
                    />
                </div>
            </Graphic>
        </Container>
    );
};
