import { Info } from '../../Popup/Popup.styles';
import { useSelector } from 'react-redux';
import { appSelector } from '../../../storage/reducers/app.reducer';

export const ScreenC1Info: React.FC = () => {
    const app = useSelector(appSelector);

    if (app.selectedModelType === 'cube') {
        return (
            <Info>
                <h2>Источники</h2>
                <p>
                    World Gastroenterology Organisation Practice Guideline
                    Hepatitis B, <span>version 2.0, February 2015</span>
                    <br />
                    <br />
                    Подробнее по ссылке
                    <a
                        href="https://worldgastroenterology.org/UserFiles/file/guidelines/hepatitis-b-russian-2015.pdf"
                        target="_blank"
                        style={{ textDecorationColor: 'var(--teal)' }}
                    >
                        <span>
                            (worldgastroenterology.org/UserFiles/file/guidelines/hepatitis-b-russian-2015.pdf)
                        </span>
                    </a>
                </p>
            </Info>
        );
    }

    return (
        <Info>
            <h2>Источники</h2>
            <p>
                EASL CPG HBV. J Hepatol <span>2017;67:370–98</span>
                <br />
                <br />
                *Данные Регистра больных вирусным гепатитом РОСПОТРЕБНАДЗОРА РВ{' '}
                <span>(2019 год), В.П. Чуланов</span>
            </p>
        </Info>
    );
};
