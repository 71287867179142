import React, { useState } from 'react';
import { Container } from '../Screen6/Screen6.styles';
import { ReactComponent as Screen32Svg } from './../../assets/images/svg/Screen32.svg';
import { ReactComponent as Screen32PSvg } from './../../assets/images/svg/Screen32-p.svg';
import { Popup } from '../../components/Popup/Popup';
import { Graphic } from '../../components/Graphic/Graphic';
import { IScreen } from '../../storage/types/app.types';
import { TitleStyled } from '../main.page.styles';

const Screen32: React.FC<IScreen> = ({ modalActive, setModalActive }) => {
    const [activeInfo, setActiveInfo] = useState<number>(0);

    const Info1 = (
        <Graphic marginTop={'0'} style={{ paddingBottom: '50px', overflow: 'auto' }}>
            <h2
                style={{
                    fontSize: 32,
                    lineHeight: '34px',
                    fontWeight: 700,
                    width: '84%',
                    marginBottom: '50px',
                }}
            >
                Исходные характеристики в исследовании режима
                СОФ/ВЕЛ у пациентов с возвратным ХГС после трансплантации печени
            </h2>
            <Screen32PSvg style={{ width: '66rem' }} />
        </Graphic>
    );

    const infoContent: JSX.Element[] = [Info1];

    return (
        <Container style={{ zIndex: 8000, overflow: 'auto' }}>
            {modalActive
                ? infoContent[activeInfo] && (
                      <Popup
                          active={modalActive}
                          setActive={setModalActive}
                          fullScreen
                          hideClose
                      >
                          {infoContent[activeInfo]}
                      </Popup>
                  )
                : null}

            <Graphic
                marginTop={`${0}`}
                style={{
                    overflow: 'auto',
                    height: 'auto',
                    padding: '0px',
                    paddingTop: '50px',
                    transform: 'scale(0.8)',
                }}
            >
                <TitleStyled
                    style={{
                        fontSize: '28px',
                        lineHeight: '32px',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginTop: '20px',
                        width: '80%',
                    }}
                >
                    Режим СОФ/ВЕЛ 12 недель продемонстрировал высокую
                    эффективность у пациентов с возвратным гепатитом С после
                    трансплантации печени
                </TitleStyled>
                <Screen32Svg />
            </Graphic>
        </Container>
    );
};

export default Screen32;
