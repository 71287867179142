import React, { useState } from 'react';
import styles from './styles.module.scss';
import MainHeaderNav from '../MainHeaderNav/MainHeaderNav';
import { MainFooter } from '../MainFooter/MainFooter';
import MainContent from '../../components/MainContent/MainContent';
import { useDispatch, useSelector } from 'react-redux';
import {
    appSelector,
    currentStepSelector,
    setGraphicModal,
} from '../../storage/reducers/app.reducer';
import { Popup } from '../Popup/Popup';
import { Sidebar } from '../Sidebar/Sidebar';
import { Screen1 } from '../../pages/Screen1/Screen1';
import { SidebarContent1 } from '../SidebarContent/SidebarContent1';
import { SidebarContent2 } from '../SidebarContent/SidebarContent2';
import { SidebarContent3 } from '../SidebarContent/SidebarContent3';
import { SidebarContent4 } from '../SidebarContent/SidebarContent4';
import { GraphicContainer } from '../../pages/main.page.styles';
import { SidebarAstral } from '../SidebarContent/SidebarAstral';
import { SidebarGCK } from '../SidebarContent/SidebarGCK';
import SidebarCubeRight from '../SidebarContent/SidebarCubeRight';
import SidebarPercents from '../SidebarContent/SidebarPercents';

interface IProps {
    type?: string;
    selectedStep?: number;
}

const Main: React.FC<IProps> = () => {
    const app = useSelector(appSelector);
    const step = useSelector(currentStepSelector);
    const dispatch = useDispatch();
    const [modalActive, setModalActive] = useState<boolean>(false);
    const [graphicModalActive, setGraphicModalActive] =
        useState<boolean>(false);
    const type = app.selectedModelType;

    let sidebarContent;
    if (app.selectedStep === 0) sidebarContent = <SidebarContent1 />;
    if (app.selectedStep === 1 || app.selectedStep === 2)
        sidebarContent = <SidebarContent2 />;
    if (app.selectedStep === 3) sidebarContent = <SidebarContent3 />;
    if (app.selectedStep === 4) sidebarContent = <SidebarContent4 />;
    if (app.selectedStep === 5) sidebarContent = <SidebarContent4 />;

    const handleGraphicModalActive = (state: boolean) => {
        dispatch(setGraphicModal(state));
        setGraphicModalActive(state);
    };

    let modalContent;
    if (step)
        modalContent = (
            <Popup active={modalActive} setActive={setModalActive}>
                <step.infoButton.Component />
            </Popup>
        );
    if (app.selectedWay)
        return (
            <div className={styles.container}>
                <MainHeaderNav
                    modalActive={modalActive}
                    setModalActive={setModalActive}
                    graphicModalActive={graphicModalActive}
                    setGraphicModalActive={handleGraphicModalActive}
                />
                {app.selectedGraphic ? (
                    <GraphicContainer>
                        <app.selectedGraphic.Component
                            //@ts-ignore 1235
                            modalActive={graphicModalActive}
                            setModalActive={handleGraphicModalActive}
                        />
                    </GraphicContainer>
                ) : null}
                {app.selectedWay === 'c' && (
                    <>
                        <SidebarAstral />
                        {app.selectedModelType !== 'cube' &&
                            step!.index <= 4 && (
                                <SidebarPercents stepIndex={step?.index} />
                            )}
                        {step?.index === 6 && <SidebarGCK />}
                    </>
                )}
                {app.selectedModelType === 'cube' &&
                    step!.index > 0 &&
                    step!.index <= 4 && (
                        <SidebarCubeRight stepIndex={step?.index} />
                    )}
                {app.selectedModelType === 'cube' && step!.index < 6 ? (
                    <Sidebar>{sidebarContent}</Sidebar>
                ) : null}
                {modalActive ? modalContent : null}
                <MainContent selectedStep={app.selectedStep} type={type} />
                <MainFooter graphicModalActive={Boolean(app.selectedGraphic)} />
            </div>
        );
    return <Screen1 />;
};

export default Main;
