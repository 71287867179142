import { Info } from '../../Popup/Popup.styles';
import { useSelector } from 'react-redux';
import { appSelector } from '../../../storage/reducers/app.reducer';

export const Screen2Info: React.FC = () => {
    const app = useSelector(appSelector);

    if (app.selectedModelType === 'cube') {
        return (
            <Info>
                <h2>Источники</h2>
                <p>
                    World Gastroenterology Organisation Practice Guideline Hepatitis B, <span>version 2.0, February 2015</span>
                    <br />
                    <br />
                    <span>Подробнее по ссылке (worldgastroenterology.org/UserFiles/file/guidelines/hepatitis-b-russian-2015.pdf)</span>
                </p>
            </Info>
        );
    }

    return (
        <Info>
            <h2>Источники</h2>
            <p>
                EASL CPG HBV. J Hepatol <span>2017;67:370–98</span>
            </p>
        </Info>
    );
};
