import styled from 'styled-components/macro';
import bg from '../../assets/images/main_content_img/bg.jpg'

export const Container = styled.div`
    h2 {
        z-index: 10000000;
        color: var(--blue);
        font-family: Seravek, sans-serif;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-bottom: 40px;
        font-weight: 700;
        font-size: 54px;
        line-height: 66px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 0 105px;
    margin: auto;
`;

export const Background = styled.div`
  position: fixed;
  z-index: 9000;
  width: 100vw;
  height: 100vh;
  background-color: #c1bebe;
  background-image: ${`url(${bg})`};
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;

export const BackgroundBlue = styled.div`
    position: fixed;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    opacity: 0.5;
    background-color: var(--blue);
`;

export const Tools = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const BG = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
`;

export const HepatitisType = styled.div`
  font-size: 140px;
`
