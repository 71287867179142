import { Graphic } from '../../components/Graphic/Graphic';
import { ReactComponent as GraphicSVG11 } from '../../assets/images/svg/Screen11.svg';
import React from 'react';
import { Screen11Img } from './Screen7.styles';
import { Popup } from '../../components/Popup/Popup';
import { Container } from '../Screen6/Screen6.styles';
import { Screen7Info } from '../../components/PopupContent/Screen7Info/Screen7Info';
import { BackgroundBlue } from '../Screen1/Screen1.styles';

interface IScreen7 {
    modalActive: any;
    setModalActive: any;
}

export const Screen7: React.FC<IScreen7> = ({
    modalActive,
    setModalActive,
}) => {
    return (
        <Container style={{ overflowY: 'auto', overflowX: 'hidden' }}>
            <Graphic
                marginTop={`${38}`}
                zIndex={1000}
                type="main"
                style={{ padding: '0px', paddingTop: '150px', }}
            >
                <GraphicSVG11 style={{ width: '90vw' }} />
            </Graphic>
            {modalActive ? (
                <Popup active={modalActive} setActive={setModalActive}>
                    <Screen7Info />
                </Popup>
            ) : null}
        </Container>
    );
};
