import React, { useEffect, useState } from 'react';
import {
    Background,
    BackgroundBlue,
    Container,
    Tools,
    HepatitisType,
} from './Screen1.styles';
import { StyledButton } from '../../components/StyledButton/StyledButton.styles';
import { setWay } from '../../storage/reducers/app.reducer';
import { useDispatch } from 'react-redux';

export const Screen1: React.FC = () => {
    const dispatch = useDispatch();
    const [wayDelay, setWayDelay] = useState<'c' | 'b' | null>(null);

    useEffect(() => {
        if (wayDelay) {
            setTimeout(() => dispatch(setWay(wayDelay)), 2000);
        }
    }, [wayDelay]);

    return (
        <div>
            <Container>
                <Background />
                {!wayDelay && <h2>Выберите сценарий</h2>}
                <Tools>
                    {(!wayDelay || wayDelay === 'b') && (
                        <StyledButton
                            zIndex={10000}
                            shade={false}
                            value="b"
                            onClick={() => setWayDelay('b')}
                        >
                            Гепатит
                            <HepatitisType>В</HepatitisType>
                        </StyledButton>
                    )}
                    {(!wayDelay || wayDelay === 'c') && (
                        <StyledButton
                            zIndex={10000}
                            shade={false}
                            style={{ backgroundColor: '#15CDAE' }}
                            value="c"
                            onClick={() => setWayDelay('c')}
                        >
                            Гепатит
                            <HepatitisType>С</HepatitisType>
                        </StyledButton>
                    )}
                </Tools>
            </Container>
        </div>
    );
};
