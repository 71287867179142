import { Graphic, Title } from '../../components/Graphic/Graphic';
import { ReactComponent as GraphicSVG23_1 } from '../../assets/images/svg/Screen23.svg';
import React from 'react';
import { Container } from '../Main/main.page.styles';

export const Screen23: React.FC = () => {
    return (
        <Container>
            <Graphic marginTop={`${150}`}>
                <Title>Профиль безопасности и эффективности ТАФ относительно ТДФ у пациентов <br/>с хроническим гепатитом В, получавших терапию в течение 5 лет</Title>
                <GraphicSVG23_1 style={{ width: '90vw' }} />
            </Graphic>
        </Container>
    );
};
