import { Graphic } from '../../components/Graphic/Graphic';
import { ReactComponent as GraphicSVG13_1 } from '../../assets/images/svg/Screen13-1.svg';
import { Graph13Desc, Graph13Img } from '../Main/main.page.styles';
import { ReactComponent as GraphicSVG13_2 } from '../../assets/images/svg/Screen13-2.svg';
import { ReactComponent as GraphicSVG13_3 } from '../../assets/images/svg/Screen13-3.svg';
import { ReactComponent as BeforeAfter } from './../../assets/images/svg/BeforeAfter.svg';
import React from 'react';
import { Container } from '../Screen6/Screen6.styles';
import { Popup } from '../../components/Popup/Popup';
import { Info } from '../../components/Popup/Popup.styles';
import { BackgroundBlue } from '../Screen1/Screen1.styles';
import { IScreen } from '../../storage/types/app.types';

export const Screen10: React.FC<IScreen> = ({
    modalActive,
    setModalActive,
}) => {
    return (
        <Container>
            <Graphic marginTop={`${16}`} zIndex={1000} type="main">
                <BeforeAfter style={{ marginTop: 100, width: '100vw' }} />
            </Graphic>
            {modalActive ? (
                <Popup active={modalActive} setActive={setModalActive}>
                    <Info style={{ padding: '16px 40px' }}>
                        <h2 style={{ fontSize: 24 }}>Источники и сокращения</h2>
                        <ol type="1" style={{ margin: 0 }}>
                            <li>АН — аналог нуклеоз(т)ида;</li>
                            <li>ОТП — ортотопическая трансплантация печени;</li>
                            <li>
                                ПВТ — противовирусная терапия
                            </li>
                            <li>
                                ТАФ — тенофовира алафенамид;
                            </li>
                            <li>
                                ТДФ — тенофовира дизопроксила фумарат;
                            </li>
                            <li>ЭТВ — энтекавир;</li>
                            <li>
                                *ТАФ в составе препарата Вемлиди® <br/> зарегистрирован для лечения хронического гепатита В
                            </li>
                        </ol>
                        <p style={{ fontSize: 16 }}>Хубутия М.Ш., Восканян С.Э., Сюткин В.Е., Чуланов В.П., Новрузбеков М.С., Пасечников В.Д. и др. Трансплантология.
                            <span>2020;12(3):231-244. https://doi.org/10/23873/2074-0506-2020-12-3-231-244</span>
                        </p>
                    </Info>
                </Popup>
            ) : null}
        </Container>
    );
};
