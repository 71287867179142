import React from 'react';
import {
    ImageContainer,
    SidebarContainerCubeStyled,
    SidebarText,
} from './sidebar.styles';
import mk1 from '../../assets/images/main_content_img/mk1.png';
import mk2 from '../../assets/images/main_content_img/mk2.png';
import mk3 from '../../assets/images/main_content_img/mk3.png';
import mk4 from '../../assets/images/main_content_img/mk4.png';

const SidebarCubeRight = ({ stepIndex }: { stepIndex?: number }) => {
    const content = [
        {
            image: mk1,
            text:
                'Избыток фиброзной ткани размещается только вокруг ветвей печеночной артерии \n' +
                'и вены, расположенных в области портальных трактов, без образования септ.',
        },
        {
            image: mk2,
            text:
                'Дополнительно \n' +
                'из фиброзной ткани образуются септы, соединительнотканные перегородки между\n' +
                'портальными трактами, располагающимися \n' +
                'на стыках печеночных долек.',
        },
        {
            image: mk3,
            text:
                'Множественные порто-портальные и порто-\n' +
                'центральные септы без формирования ложных\n' +
                'печеночных долек, то есть без цирроза.',
        },
        {
            image: mk4,
            text:
                'На этом этапе наблюдаются массивные порто-портальные и порто-центральные септы и\n' +
                'формируются ложные печеночные дольки.',
        },
    ];
    const currentSlide =
        stepIndex && stepIndex > 0 ? content?.[stepIndex - 1] : null;

    return (
        <SidebarContainerCubeStyled>
            <ImageContainer>
                <img src={currentSlide?.image} />
            </ImageContainer>
            <SidebarText>{currentSlide?.text}</SidebarText>
        </SidebarContainerCubeStyled>
    );
};

export default SidebarCubeRight;
