import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { IAppData, IWayStepHeaderButton } from '../types/app.types';
import { Screen6 } from '../../pages/Screen6/Screen6';
import { Screen7 } from '../../pages/Screen7/Screen7';
import { Screen10 } from '../../pages/Screen10/Screen10';
import { Screen12 } from '../../pages/Screen12/Screen12';
import { Screen15 } from '../../pages/Screen15/Screen15';
import { Screen23 } from './../../pages/Screen23/Screen23';
import { Screen10Info } from '../../components/PopupContent/Screen10Info/Screen10Info';
import { Screen11Info } from '../../components/PopupContent/Screen11Info/Screen11Info';
import { Screen9Info } from '../../components/PopupContent/Screen9Info/Screen9Info';
import { Screen8Info } from '../../components/PopupContent/Screen8Info/Screen8Info';
import { Screen5Info } from '../../components/PopupContent/Screen5Info/Screen5Info';
import { Screen4Info } from '../../components/PopupContent/Screen4Info/Screen4Info';
import { Screen3Info } from '../../components/PopupContent/Screen3Info/Screen3Info';
import { Screen2Info } from '../../components/PopupContent/Screen2Info/Screen2Info';
import { Screen12Info } from '../../components/PopupContent/Screen12Info/Screen12Info';
import { Screen16 } from '../../pages/Screen16/Screen16';
import { Screen17 } from '../../pages/Screen17/Screen17';
import Screen24 from '../../pages/Screen24/Screen24';
import { ScreenC1Info } from '../../components/PopupContent/ScreenC1Info/ScreenC1Info';
import { ScreenC2Info } from '../../components/PopupContent/ScreenC2Info/ScreenC2Info';
import Screen25 from '../../pages/Screen25/Screen25';
import Screen27 from '../../pages/Screen27/Screen27';
import Screen28 from '../../pages/Screen28/Screen28';
import Screen29 from '../../pages/Screen29/Screen29';
import Screen30 from '../../pages/Screen30/Screen30';
import Screen31 from '../../pages/Screen31/Screen31';
import Screen32 from '../../pages/Screen32/Screen32';
import { Screen33 } from '../../pages/Screen33/Screen33';
import { Screen34 } from '../../pages/Screen34/Screen34';

const initialData: IAppData = {
    ways: [
        {
            type: 'Гепатит В',
            key: 'b',
            mainColor: '#00ACC8',
            models: [
                {
                    id: 'm0',
                    url: 'model1.glb',
                },
                {
                    id: 'm1',
                    url: 'model2.glb',
                },
                {
                    id: 'm2',
                    url: 'model3.glb',
                },
                {
                    id: 'm3',
                    url: 'model4.glb',
                },
                {
                    id: 'm4',
                    url: 'model5.glb',
                },
                {
                    id: 'm5',
                    url: 'model6.glb',
                },
                {
                    id: 'm6',
                    url: 'model7.glb',
                },
                {
                    id: 'm7',
                    url: 'model8.glb',
                },
            ],
            steps: [
                {
                    index: 0,
                    model: 'm0',
                    title: 'Отсутствие фиброза',
                    playSoundUrl:
                        'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3',
                    headerButtons: [
                        {
                            index: 0,
                            text: 'Подробнее про исследование ТАФ',
                            secondaryText:
                                'при компенсированном заболевании (F0-F4)',
                            buttonText:
                                'Вирусологическая супрессия и нормализация уровня ALT через 5 лет',
                            title: 'ТАФ при компенсированном заболевании',
                            titleFontSize: 24,
                            Component: Screen6,
                            infoButton: {
                                Component: Screen23,
                            },
                        },
                        {
                            index: 1,
                            text: 'Подробнее про исследование ТАФ',
                            secondaryText:
                                'при декомпенсированном циррозе (F4)',
                            title: 'Исследование ТАФ при декомпенсации функции печени¹',
                            titleFontSize: 28,
                            Component: Screen7,
                            infoButton: {
                                Component: Screen23,
                            },
                        },
                    ],
                    infoButton: {
                        Component: Screen2Info,
                    },
                    Profile: Screen16,
                    Instruction: Screen17,
                    label: 'F0',
                },
                {
                    index: 1,
                    model: 'm0',
                    title: 'Слабый фиброз',
                    playSoundUrl:
                        'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3',
                    headerButtons: [
                        {
                            index: 0,
                            text: 'Подробнее про исследование ТАФ',
                            secondaryText:
                                'при компенсированном заболевании (F0-F4)',
                            buttonText:
                                'Вирусологическая супрессия и нормализация уровня ALT через 5 лет',
                            title: 'ТАФ при компенсированном заболевании',
                            titleFontSize: 24,
                            Component: Screen6,
                            infoButton: {
                                Component: Screen23,
                            },
                        },
                        {
                            index: 1,
                            text: 'Подробнее про исследование ТАФ',
                            secondaryText:
                                'при декомпенсированном циррозе (F4)',
                            title: 'Исследование ТАФ при декомпенсации функции печени¹',
                            titleFontSize: 28,
                            Component: Screen7,
                            infoButton: {
                                Component: Screen23,
                            },
                        },
                    ],
                    infoButton: {
                        Component: Screen3Info,
                    },
                    Profile: Screen16,
                    Instruction: Screen17,
                    label: 'F1',
                },
                {
                    index: 2,
                    model: 'm0',
                    title: 'Умеренный фиброз',
                    headerButtons: [
                        {
                            index: 0,
                            text: 'Подробнее про исследование ТАФ',
                            secondaryText:
                                'при компенсированном заболевании (F0-F4)',
                            buttonText:
                                'Вирусологическая супрессия и нормализация уровня ALT через 5 лет',
                            title: 'ТАФ при компенсированном заболевании',
                            titleFontSize: 24,
                            Component: Screen6,
                            infoButton: {
                                Component: Screen23,
                            },
                        },
                        {
                            index: 1,
                            text: 'Подробнее про исследование ТАФ',
                            secondaryText:
                                'при декомпенсированном циррозе (F4)',
                            title: 'Исследование ТАФ при декомпенсации функции печени¹',
                            titleFontSize: 28,
                            Component: Screen7,
                            infoButton: {
                                Component: Screen23,
                            },
                        },
                    ],
                    infoButton: {
                        Component: Screen4Info,
                    },
                    Profile: Screen16,
                    Instruction: Screen17,
                    label: 'F2',
                },
                {
                    index: 3,
                    model: 'm0',
                    title: 'Выраженный фиброз',
                    playSoundUrl:
                        'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3',
                    headerButtons: [
                        {
                            index: 0,
                            text: 'Подробнее про исследование ТАФ',
                            secondaryText:
                                'при компенсированном заболевании (F0-F4)',
                            buttonText:
                                'Вирусологическая супрессия и нормализация уровня ALT через 5лет',
                            title: 'ТАФ при компенсированном заболевании',
                            titleFontSize: 24,
                            Component: Screen6,
                            infoButton: {
                                Component: Screen23,
                            },
                        },
                        {
                            index: 1,
                            text: 'Подробнее про исследование ТАФ',
                            secondaryText:
                                'при декомпенсированном циррозе (F4)',
                            title: 'Исследование ТАФ при декомпенсации функции печени¹',
                            titleFontSize: 28,
                            Component: Screen7,
                            infoButton: {
                                Component: Screen23,
                            },
                        },
                    ],
                    infoButton: {
                        Component: Screen5Info,
                    },
                    Profile: Screen16,
                    Instruction: Screen17,
                    label: 'F3',
                },
                {
                    index: 4,
                    model: 'm0',
                    title: 'Цирроз',
                    headerButtons: [
                        {
                            index: 0,
                            text: 'Подробнее про исследование ТАФ',
                            secondaryText:
                                'при компенсированном заболевании (F0-F4)',
                            buttonText:
                                'Вирусологическая супрессия и нормализация уровня ALT через 5 лет',
                            title: 'ТАФ при компенсированном заболевании',
                            titleFontSize: 24,
                            Component: Screen6,
                            infoButton: {
                                Component: Screen23,
                            },
                        },
                        {
                            index: 1,
                            text: 'Подробнее про исследование ТАФ',
                            secondaryText:
                                'при декомпенсированном циррозе (F4)',
                            title: 'Исследование ТАФ при декомпенсации функции печени¹',
                            titleFontSize: 28,
                            Component: Screen7,
                            infoButton: {
                                Component: Screen23,
                            },
                        },
                    ],
                    infoButton: {
                        Component: Screen8Info,
                    },
                    Profile: Screen16,
                    Instruction: Screen17,
                    label: 'F4',
                },
                {
                    index: 5,
                    model: 'm0',
                    title: 'Декомпенсация',
                    headerButtons: [
                        {
                            index: 1,
                            text: 'Подробнее про исследование ТАФ',
                            secondaryText:
                                'при декомпенсации в реальной практике',
                            title: 'Исследование ТАФ при декомпенсации функции печени¹',
                            titleFontSize: 24,
                            Component: Screen7,
                            infoButton: {
                                Component: Screen23,
                            },
                        },
                    ],
                    infoButton: {
                        Component: Screen9Info,
                    },
                    Profile: Screen16,
                    Instruction: Screen17,
                    description: 'F4, Классы В и С по ЧП',
                },
                {
                    index: 6,
                    model: 'm0',
                    title: 'Гепатоцеллюлярная карцинома',
                    playSoundUrl:
                        'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-3.mp3',
                    headerButtons: [
                        {
                            index: 0,
                            text: 'Подробнее про исследование ТАФ',
                            secondaryText: 'при гепатоцеллюлярной карциноме',
                            title: 'Исследование ТАФ при гепатоцеллюлярной карциноме',
                            titleFontSize: 18,
                            Component: Screen12,
                            infoButton: {
                                Component: Screen12Info,
                            },
                        },
                    ],
                    infoButton: {
                        Component: Screen11Info,
                    },
                    Profile: Screen16,
                    Instruction: Screen17,
                },
                {
                    index: 7,
                    model: 'm0',
                    title: 'Трансплантация',
                    headerButtons: [
                        {
                            index: 0,
                            text: 'Подробнее про терапию',
                            secondaryText: 'ДО и ПОСЛЕ ОТП',
                            buttonText:
                                'Подробнее про терапию\n' + 'ДО и ПОСЛЕ ОТП \n',

                            Component: Screen10,
                            infoButton: {
                                Component: Screen23,
                            },
                        },
                        {
                            index: 1,
                            text: 'Подробнее про исследование ТАФ',
                            secondaryText: 'при трансплантации',
                            buttonText:
                                'Подробнее про исследование\n' +
                                'TAF при декомпенсации',

                            Component: Screen15,
                            infoButton: {
                                Component: Screen23,
                            },
                        },
                    ],
                    infoButton: {
                        Component: Screen10Info,
                    },
                    Profile: Screen16,
                    Instruction: Screen17,
                },
            ],
        },
        {
            type: 'Гепатит C',
            key: 'c',
            mainColor: '#15CDAE',
            models: [
                {
                    id: 'm0',
                    url: 'model1.glb',
                },
                {
                    id: 'm1',
                    url: 'model2.glb',
                },
                {
                    id: 'm2',
                    url: 'model3.glb',
                },
                {
                    id: 'm3',
                    url: 'model4.glb',
                },
                {
                    id: 'm4',
                    url: 'model5.glb',
                },
                {
                    id: 'm5',
                    url: 'model6.glb',
                },
                {
                    id: 'm6',
                    url: 'model7.glb',
                },
                {
                    id: 'm7',
                    url: 'model8.glb',
                },
            ],
            steps: [
                {
                    index: 0,
                    model: 'm0',
                    title: 'Отсутствие фиброза',
                    playSoundUrl:
                        'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3',
                    headerButtons: [
                        {
                            index: 0,
                            text: 'Эффективность СОФ/ВЕЛ в течение 12 недель',
                            secondaryText:
                                'у пациентов с ХГС ГТ 1–6 и фиброзом',
                            title: 'Эффективность СОФ/ВЕЛ в течение 12 недель у пациентов с ХГС ГТ 1-6 и фиброзом F0-F2 (интегрированный анализ данных)',
                            titleFontSize: 20,
                            Component: Screen24,
                            hideTitleOnInfo: true,
                            infoButton: {
                                Component: Screen23,
                            },
                        },
                    ],
                    infoButton: {
                        Component: ScreenC1Info,
                    },
                    Profile: Screen33,
                    Instruction: Screen34,
                    label: 'F0',
                },
                {
                    index: 1,
                    model: 'm0',
                    title: 'Слабый фиброз',
                    playSoundUrl:
                        'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3',
                    headerButtons: [
                        {
                            index: 0,
                            text: 'Эффективность СОФ/ВЕЛ в течение 12 недель',
                            secondaryText:
                                'у пациентов с ХГС ГТ 1–6 и фиброзом',
                            titleFontSize: 20,
                            title: 'Эффективность СОФ/ВЕЛ в течение 12 недель у пациентов с ХГС ГТ 1-6 и фиброзом F0-F2 (интегрированный анализ данных)',
                            hideTitleOnInfo: true,
                            Component: Screen24,
                            infoButton: {
                                Component: Screen23,
                            },
                        },
                    ],
                    infoButton: {
                        Component: ScreenC2Info,
                    },
                    Profile: Screen33,
                    Instruction: Screen34,
                    label: 'F1',
                },
                {
                    index: 2,
                    model: 'm0',
                    title: 'Умеренный фиброз',
                    headerButtons: [
                        {
                            index: 0,
                            text: 'Эффективность СОФ/ВЕЛ в течение 12 недель',
                            secondaryText:
                                'у пациентов с ХГС ГТ 1–6 и фиброзом',
                            title: 'Эффективность СОФ/ВЕЛ в течение 12 недель у пациентов с ХГС ГТ 1-6 и фиброзом F0-F2 (интегрированный анализ данных)',
                            titleFontSize: 20,
                            hideTitleOnInfo: true,
                            Component: Screen24,
                            infoButton: {
                                Component: Screen23,
                            },
                        },
                    ],
                    infoButton: {
                        Component: ScreenC2Info,
                    },
                    Profile: Screen33,
                    Instruction: Screen34,
                    label: 'F2',
                },
                {
                    index: 3,
                    model: 'm0',
                    title: 'Выраженный фиброз',
                    playSoundUrl:
                        'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3',
                    headerButtons: [
                        {
                            index: 0,
                            text: 'Эффективность СОФ/ВЕЛ',
                            secondaryText:
                                'у пациентов с ХГС генотипов 1-6 и продвинутой стадией фиброза',
                            buttonText:
                                'Вирусологическая супрессия и нормализация уровня ALT через 5лет',
                            titleFontSize: 20,
                            hideTitleOnInfo: true,
                            title:
                                'Эффективность режима СОФ/ВЕЛ\n' +
                                'у пациентов с ХГС генотипов 1-6 и продвинутой стадией фиброза печени (F3)',
                            Component: Screen25,
                            infoButton: {
                                Component: Screen23,
                            },
                        },
                        {
                            index: 1,
                            text: 'Сохраняется проблема',
                            secondaryText:
                                'высокой распространен-ности тяжелых поражений печени',
                            title: '',
                            titleFontSize: 22,
                            Component: Screen27,
                            infoButton: {
                                Component: Screen23,
                            },
                        },
                    ],
                    infoButton: {
                        Component: ScreenC2Info,
                    },
                    Profile: Screen33,
                    Instruction: Screen34,
                    label: 'F3',
                },
                {
                    index: 4,
                    model: 'm0',
                    title: 'Цирроз',
                    headerButtons: [
                        {
                            index: 0,
                            text: 'Эффективность СОФ/в течение 12 недель',
                            secondaryText:
                                'у пациентов с ХГС ГТ 1–6 при наличии цирроза печени',
                            buttonText:
                                'Вирусологическая супрессия и нормализация уровня ALT через 5 лет',
                            title: 'Эффективность режима СОФ/ВЕЛ у пациентов с ХГС генотипов 1-6 и компенсированным циррозом печени (F4, Чайлд-Пью А)',
                            titleFontSize: 24,
                            hideTitleOnInfo: true,
                            Component: Screen28,
                            infoButton: {
                                Component: Screen23,
                            },
                        },
                        {
                            index: 1,
                            text: 'Сохраняется проблема',
                            secondaryText:
                                'высокой распространен-ности тяжелых поражений печени',
                            title: '',
                            titleFontSize: 22,
                            Component: Screen27,
                            infoButton: {
                                Component: Screen23,
                            },
                        },
                    ],
                    infoButton: {
                        Component: ScreenC2Info,
                    },
                    Profile: Screen33,
                    Instruction: Screen34,
                    label: 'F4',
                },
                {
                    index: 5,
                    model: 'm0',
                    title: 'Декомпенсация',
                    headerButtons: [
                        {
                            index: 1,
                            text: 'Планирование ПВТ ХГС',
                            secondaryText: 'при декомпенсированном ЦП',
                            title: '',
                            Component: Screen29,
                            infoButton: {
                                Component: Screen23,
                            },
                        },
                        {
                            index: 1,
                            text: 'Эффективность и безопасность схем на основе СОФ у пациентов с ХГС',
                            secondaryText:
                                'с декомпенсированным циррозом печени',
                            title: '',
                            Component: Screen30,
                            infoButton: {
                                Component: Screen23,
                            },
                        },
                    ],
                    infoButton: {
                        Component: Screen9Info,
                    },
                    Profile: Screen33,
                    Instruction: Screen34,
                    description: 'F4, Классы В и С по ЧП',
                },
                {
                    index: 6,
                    model: 'm0',
                    title: 'Гепатоцеллюлярная карцинома',
                    playSoundUrl:
                        'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-3.mp3',
                    headerButtons: [
                        {
                            index: 0,
                            text: 'Ключевые научные аспекты:',
                            secondaryText:
                                'применение СОФ/ВЕЛ у пациентов с ГЦК',
                            title: 'Ключевые научные аспекты: применение СОФ/ВЕЛ у пациентов с ГЦК',
                            titleFontSize: 30,
                            Component: Screen31,
                            infoButton: {
                                Component: Screen12Info,
                            },
                        },
                    ],
                    infoButton: {
                        Component: Screen11Info,
                    },
                    Profile: Screen33,
                    Instruction: Screen34,
                },
                {
                    index: 7,
                    model: 'm0',
                    title: 'Трансплантация',
                    headerButtons: [
                        {
                            index: 0,
                            text: 'Режим СОФ/ВЕЛ для пациентов',
                            secondaryText: 'с возвратным гепатитом С',
                            buttonText:
                                'Подробнее про терапию\n' + 'ДО и ПОСЛЕ ОТП \n',
                            title: '',
                            hideTitleOnInfo: true,
                            Component: Screen32,
                            infoButton: {
                                Component: Screen23,
                            },
                        },
                    ],
                    infoButton: {
                        Component: Screen10Info,
                    },
                    Profile: Screen33,
                    Instruction: Screen34,
                },
            ],
        },
    ],
};

export interface IAppState {
    data: IAppData;
    loaded: boolean;
    selectedStep: number;
    selectedWay?: string;
    selectedGraphic: IWayStepHeaderButton | null;
    selectedModelType?: string;
    playing: boolean;
    graphicModal: boolean;
}

const targetStep = process.env.REACT_APP_PAGE?.includes('step')
    ? +process.env.REACT_APP_PAGE!.replace('step', '')
    : 0;

console.log(process.env.REACT_APP_PAGE);

const initialState: IAppState = {
    loaded: false,
    selectedStep: targetStep,
    selectedGraphic: null,
    selectedModelType: 'liver',
    playing: false,
    data: initialData,
    selectedWay: '',
    graphicModal: false,
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setLoadedValue: (state, action: PayloadAction<boolean>): void => {
            state.loaded = action.payload;
        },
        setWay: (state, action: PayloadAction<string>): void => {
            state.selectedWay = action.payload;
        },
        setStep: (state, action: PayloadAction<number>): void => {
            state.selectedStep = action.payload;
        },
        setSelectedGraphic: (
            state,
            action: PayloadAction<IWayStepHeaderButton | null>
        ) => {
            state.selectedGraphic = action.payload;
        },
        setGraphicModal: (state, action: PayloadAction<boolean>) => {
            state.graphicModal = action.payload;
        },
        setModelType: (state, action: PayloadAction<string>): void => {
            state.selectedModelType = action.payload;
        },
        setPlaying: (state, action: PayloadAction<boolean>) => {
            state.playing = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const $app_actions = appSlice.actions;

export const appSelector = (state: RootState): IAppState => state.app;
export const waySelector = createSelector(appSelector, (app) => {
    return app.data.ways.find((way) => way.key === app.selectedWay);
});
export const currentStepSelector = createSelector(
    [appSelector, waySelector],
    (app, way) => {
        return way?.steps[app.selectedStep];
    }
);

export default appSlice.reducer;

export const {
    setWay,
    setStep,
    setSelectedGraphic,
    setModelType,
    setPlaying,
    setGraphicModal,
} = appSlice.actions;
