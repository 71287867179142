import React from 'react';
import { SidebarPercentsStyled, SidebarPercentsText } from './sidebar.styles';
import { ReactComponent as Percent0Svg } from '../../assets/images/svg/Percent0.svg';
import { ReactComponent as Percent1Svg } from '../../assets/images/svg/Percent1.svg';
import { ReactComponent as Percent2Svg } from '../../assets/images/svg/Percent2.svg';
import { ReactComponent as Percent3Svg } from '../../assets/images/svg/Percent3.svg';
import { ReactComponent as Percent4Svg } from '../../assets/images/svg/Percent4.svg';

const SidebarPercents = ({ stepIndex }: { stepIndex?: number }) => {
    const content = [
        {
            svg: Percent0Svg,
            text: 'До 36% пациентов с ХГС в России диагностируются на стадии отсутствия фиброза*',
        },
        {
            svg: Percent1Svg,
            text: 'До 24% пациентов с ХГС в России диагностируются на стадии слабого фиброза*',
        },
        {
            svg: Percent2Svg,
            text: 'До 18% пациентов с ХГС в России диагностируются на стадии умеренного фиброза*',
        },
        {
            svg: Percent3Svg,
            text: 'Около 11% пациентов с ХГС в России диагностируются на стадии выраженного фиброза*',
        },
        {
            svg: Percent4Svg,
            text2: 'До 25% пациентов с ХГС имеют продвинутую стадию фиброза или цирроз печени*',
            text: 'Около 14% пациентов с ХГС в России диагностируются на стадии цирроза печени*',
        },
    ];

    const currentSlide = stepIndex !== undefined ? content?.[stepIndex] : null;

    return (
        <>
            {currentSlide?.text2 && (
                <SidebarPercentsStyled
                    style={{
                        left: '20px',
                        width: '130px',
                        borderTopRightRadius: currentSlide?.text2 ? 0 : null,
                        borderBottomRightRadius: currentSlide?.text2 ? 0 : null,
                        boxShadow: currentSlide?.text2 ? 'none' : null,
                    }}
                >
                    <SidebarPercentsText>
                        {currentSlide?.text2}
                    </SidebarPercentsText>
                </SidebarPercentsStyled>
            )}
            <SidebarPercentsStyled
                style={{
                    left: currentSlide?.text2 ? '140px' : '20px',
                    width: '194px',
                    borderTopLeftRadius: currentSlide?.text2 ? 0 : null,
                    borderBottomLeftRadius: currentSlide?.text2 ? 0 : null,
                    boxShadow: currentSlide?.text2 ? 'none' : null,
                }}
            >
                {currentSlide && <currentSlide.svg />}
                <SidebarPercentsText>{currentSlide?.text}</SidebarPercentsText>
            </SidebarPercentsStyled>
        </>
    );
};

export default SidebarPercents;
