import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root {
    --teal: ${({ mainColor }) => mainColor || '#00ACC8'};
    --yellow: #F5C400;
    --blue: #1F3266;
    --gray-1: #5C5B5B;
    --gray-2: #969899;
    --custom-gray: #EFEFEF;
    --custom-gray-2: #D8D8D8;
    --light-blue: #14AFE6;
  }

  body {
    padding: 0;
    margin: 0;
    background-color: #EFEFEF;
  }

  html {
    box-sizing: border-box;
    font-size: 1vw;
    -webkit-font-smoothing: antialiased;
  }

  *, *:before, *:after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    user-select: none;
  }

  .main-nav-btn {
    font-family: Seravek, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    background: #002E6D;
    padding: 0 35px;
    height: 65px;
    border: none;
    border-radius: 15px;
    max-width: 300px;
  }

  .main-nav-btn-info {
    background: var(--teal);
    border-radius: 50%;
    height: 65px;
    border: none;
    padding: 0 10px;
    box-sizing: inherit;
  }

  .main-nav-btn-info:active {
    background-color: var(--blue);
  }

  .dg.ac {
    z-index: 9999
  }

  .openglWrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  #gradientHolder canvas {
    z-index: 1;
  }
`;
