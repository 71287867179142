import { Graphic } from '../../components/Graphic/Graphic';
import { ReactComponent as GraphicSVG14 } from '../../assets/images/svg/Screen14.svg';
import React from 'react';
import { Popup } from '../../components/Popup/Popup';
import { Container } from '../Screen6/Screen6.styles';
import { Screen12Info } from '../../components/PopupContent/Screen12Info/Screen12Info';
import { IScreen } from '../../storage/types/app.types';

export const Screen12: React.FC<IScreen> = ({
    modalActive,
    setModalActive,
}) => {
    return (
        <Container>
            <Graphic marginTop={`${24}`} zIndex={1000} type="main">
                <GraphicSVG14 style={{ width: '100vw', marginTop: 50 }}/>
            </Graphic>
            {modalActive ? (
                <Popup
                    active={modalActive}
                    setActive={setModalActive}
                    type="big"
                    fullScreen
                    hideClose
                >
                    <Screen12Info />
                </Popup>
            ) : null}
        </Container>
    );
};
