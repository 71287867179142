import React from 'react';
import { Container } from '../../../pages/Main/main.page.styles';
import { Description, Title } from './Screen7Info.styles';
import { Info } from '../../Popup/Popup.styles';

export const Screen7Info: React.FC = () => {
    return (
        <Info>
            <h2>Расшифровка сокращений и источники</h2>
            <ol type='1'>
                <li>ВГВ — вирус гепатита В;</li>
                <li>ТАФ — тенофовира алафенамид;</li>
                <li>ТДФ — тенофовира дизопроксила фумарат;</li>
                <li>ЭТВ — энтекавир;</li>
                <li>ХГВ — хронический вирусный гепатит B;</li>
                <li>рСКФ — расчётная скорость клубочковой фильтрации;</li>
                <li>
                    CKD-EPI — Сотрудничество в области эпидемиологии хронических
                    заболеваний почек;
                </li>
                <li>
                    {
                        'Низкая виремия, ВГВ ДНК < 2000 МЕ/мл. Группа слабого ответа, ВГВ ДНК ≥ 2000МЕ/мл.'
                    }
                </li>
                <li>1. Zeng A-J, et al. AASLD 2021. 824</li>
            </ol>
        </Info>
    );
};
