import React from 'react';
import styles from './styles.module.scss';

import { useDispatch, useSelector } from 'react-redux';
import {
    appSelector,
    currentStepSelector,
    setModelType,
    setStep,
    waySelector,
} from '../../storage/reducers/app.reducer';
import { Description, FooterButton, Tools } from './MainFooter.styles';
import { PlayButton } from '../PlayMusicBtn/PlayMusicBtn';

export const MainFooter: React.FC<{ graphicModalActive?: boolean }> = ({
    graphicModalActive,
}) => {
    const way = useSelector(waySelector);
    const step = useSelector(currentStepSelector);
    const dispatch = useDispatch();

    const handleStepChange = (value: number): void => {
        if (graphicModalActive) {
            return;
        }

        if (step && step.index !== value) {
            dispatch(setModelType('liver'));
            dispatch(setStep(value));
        }
    };

    const urls = way!.steps.map((item) => item.playSoundUrl);

    if (way)
        return (
            <footer className={styles.footer}>
                {/*<PlayButton urls={urls} active={graphicModalActive} />*/}
                <nav className={styles.footerNav}>
                    <Tools>
                        {way.steps.map((item) => (
                            <FooterButton
                                key={item.index}
                                way={way.key}
                                onClick={() => handleStepChange(item.index)}
                                index={item.index}
                                step={step}
                                item={item}
                            >
                                {item.label && (
                                    <span
                                        style={
                                            item.index < 5
                                                ? { whiteSpace: 'pre-wrap' }
                                                : undefined
                                        }
                                    >
                                        {item.label}
                                    </span>
                                )}
                                <span className={styles.title}>
                                    {item.title}
                                </span>
                                {item.description ? (
                                    <Description>
                                        {item.description}
                                    </Description>
                                ) : null}
                            </FooterButton>
                        ))}
                    </Tools>
                </nav>
            </footer>
        );
    else return null;
};
