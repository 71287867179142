import { CloseBtn, Container, Content } from './Popup.styles';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { ReactComponent as Cross } from '../../assets/icons/svg/Cross.svg';

interface IModal {
    active: boolean;
    setActive: Dispatch<SetStateAction<boolean>>;
    fullScreen?: boolean;
    type?: string;
    hideClose?: boolean;
    style?: any;
}

export const Popup: React.FC<IModal> = ({
    children,
    active,
    setActive,
    type,
    fullScreen,
    hideClose,
    style,
}) => {
    useEffect(() => {
        return () => setActive(false);
    }, []);

    return (
        <Container
            onClick={() => setActive(false)}
            active={active}
        >
            <Content
                onClick={(e: Event) => e.stopPropagation()}
                type={type}
                fullScreen={fullScreen}
                style={style}
            >
                {!hideClose && (
                    <CloseBtn onClick={() => setActive(false)}>
                        <Cross />
                    </CloseBtn>
                )}
                {children}
            </Content>
        </Container>
    );
};
