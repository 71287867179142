import styled from 'styled-components/macro';

export const SidebarContent = styled.ul`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    color: var(--blue);
    padding-bottom: 12px;
    ul {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    li {
        padding: 0;
        display: flex;
        gap: 12px;
        font-family: 'Seravek', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }
`;

export const SidebarBlock = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  width: 250px;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  color: var(--blue);
  z-index: 998;
  position: absolute;
  left: 18px;
  bottom: 192px;
  border: none;
  border-radius: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  
  @media screen and (max-width: 1024px) {
    bottom: 150px;
  }
`;

export const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    height: 60px;
    width: 250px;
    background-color: #FFFFFF;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    color: var(--blue);
    z-index: 1000;
    border: none;
    border-radius: 16px;
    span {
        padding-right: 0.5rem;
    }
`;
