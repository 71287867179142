import React, { useState } from 'react';
import { Container } from '../Screen6/Screen6.styles';
import { ReactComponent as Screen27Svg } from './../../assets/images/svg/Screen27.svg';
import { ReactComponent as Screen272Svg } from './../../assets/images/svg/Screen27-2.svg';
import { Popup } from '../../components/Popup/Popup';
import { Graphic } from '../../components/Graphic/Graphic';
import { IScreen } from '../../storage/types/app.types';
import { TitleStyled } from '../main.page.styles';
import { Slider } from '../../components/Slider/Slider';
import { Info } from '../../components/Popup/Popup.styles';

const Screen27: React.FC<IScreen> = ({ modalActive, setModalActive }) => {
    const [activeInfo, setActiveInfo] = useState<number>(0);

    const Slide1 = (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}
        >
            <TitleStyled
                style={{ width: '60%', fontSize: '28px', lineHeight: '32px' }}
            >
                FDA предупреждает о риске тяжелых поражений печени при
                применении режимов ПППД, содержащих ингибиторы протеазы ВГС
                <sup>1</sup>
            </TitleStyled>
            <Screen27Svg style={{ width: '65%' }} />
        </div>
    );
    const Slide2 = (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}
        >
            <TitleStyled
                style={{ width: '60%', fontSize: '28px', lineHeight: '32px' }}
            >
                Европейская ассоциация по изучению печени (EASL) также
                ограничивает назначение ингибиторов протеазы (ИП) у пациентов с
                тяжелыми поражениями печени
            </TitleStyled>
            <Screen272Svg style={{ width: '60%' }} />
        </div>
    );

    const Info1 = (
        <Info>
            <h2>Источники и сокращения</h2>
            <p>
                ГЦК — гепатоцеллюлярная карцинома;
                <br />
                <br />
                ПППД — препарат прямого противовирусного действия;
                <br />
                <br />
                ИП - ингибиторы протеазы ВГС;
                <br />
                <br />
                ВГС - вирус гепатита С;
                <br />
                <br />
                1. EASL Recommendations on Treatment of Hepatitis C 2018 J
                Hepatol. 2018;4461-5112
            </p>
        </Info>
    );

    const Info2 = (
        <Info>
            <h2>Источники и сокращения</h2>
            <p>
                ГЦК — гепатоцеллюлярная карцинома;
                <br />
                <br />
                EASL Recommendations on Treatment of Hepatitis C 2018 J Hepatol.
                2018;4461-5112
            </p>
        </Info>
    );

    const updateInfo = (slide: number) => {
        setActiveInfo(slide);
    };

    const infoContent: JSX.Element[] = [Info1, Info2];

    const sliderContent: JSX.Element[] = [Slide1, Slide2];

    return (
        <Container style={{ zIndex: 8000, overflow: 'scroll' }}>
            {modalActive
                ? infoContent[activeInfo] && (
                      <Popup active={modalActive} setActive={setModalActive}>
                          {infoContent[activeInfo]}
                      </Popup>
                  )
                : null}

            <Graphic
                marginTop={`${0}`}
                style={{
                    overflow: 'scroll',
                }}
            >
                <Slider
                    content={sliderContent}
                    updateInfo={updateInfo}
                    startingSlide={0}
                />
            </Graphic>
        </Container>
    );
};

export default Screen27;
