import React, { useState } from 'react';
import { Container } from '../Screen6/Screen6.styles';
import { ReactComponent as Screen30Svg } from './../../assets/images/svg/Screen30.svg';
import { ReactComponent as Screen302Svg } from './../../assets/images/svg/Screen30-2.svg';
import { ReactComponent as Screen303Svg } from './../../assets/images/svg/Screen30-3.svg';
import { ReactComponent as Screen304Svg } from './../../assets/images/svg/Screen30-4.svg';
import { Popup } from '../../components/Popup/Popup';
import { Graphic } from '../../components/Graphic/Graphic';
import { IScreen } from '../../storage/types/app.types';
import { TitleStyled } from '../main.page.styles';
import { Slider } from '../../components/Slider/Slider';
import { Info } from '../../components/Popup/Popup.styles';

const Screen30: React.FC<IScreen> = ({ modalActive, setModalActive }) => {
    const [activeInfo, setActiveInfo] = useState<number>(0);

    const Slide1 = (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}
        >
            <TitleStyled
                style={{
                    fontSize: '28px',
                    lineHeight: '32px',
                    width: '60%',
                }}
            >
                Эффективность и безопасность схем на основе СОФ у пациентов с
                ХГС с декомпенсированным циррозом печени
            </TitleStyled>
            <h2
                style={{
                    fontFamily: 'Seravek, sans-serif',
                    justifySelf: 'center',
                    alignSelf: 'center',
                    fontWeight: 700,
                    fontSize: 24,
                    lineHeight: '32px',
                    textAlign: 'center',
                    color: 'var(--blue)',
                    width: '86%',
                }}
            >
                Систематический обзор и мета-анализ 5302 пациентов с
                декомпенсированным циррозом на основании 33 исследований с
                октября 2010 по октябрь 2020гг.
            </h2>
            <Screen30Svg style={{ width: '80%' }} />
        </div>
    );
    const Slide2 = (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}
        >
            <TitleStyled
                style={{
                    width: '60%',
                    fontSize: '28px',
                    lineHeight: '32px',
                    marginBottom: '20px',
                }}
            >
                Эффективность СОФ/ВЕЛ ± РБВ у пациентов с декомпенсированным
                циррозом печени
            </TitleStyled>
            <Screen302Svg style={{ width: '73%' }} />
        </div>
    );
    const Slide3 = (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}
        >
            <TitleStyled
                style={{
                    width: '70%',
                    fontSize: '28px',
                    lineHeight: '32px',
                    marginBottom: '20px',
                }}
            >
                СОФ/ВЕЛ ± РБВ у пациентов с декомпенсированным циррозом:
                Изменение баллов по шкале MELD
            </TitleStyled>
            <Screen303Svg style={{ width: '70%' }} />
        </div>
    );
    const Slide4 = (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}
        >
            <TitleStyled
                style={{
                    width: '70%',
                    fontSize: '28px',
                    lineHeight: '32px',
                    marginBottom: '20px',
                }}
            >
                СОФ/ВЕЛ ± РБВ у пациентов с декомпенсированным циррозом:
                Изменения класса по Чайлд-Пью у пациентов, достигших СВО12
            </TitleStyled>
            <h2
                style={{
                    fontFamily: 'Seravek, sans-serif',
                    justifySelf: 'center',
                    alignSelf: 'center',
                    fontWeight: 700,
                    fontSize: 28,
                    lineHeight: '32px',
                    textAlign: 'center',
                    color: 'var(--blue)',
                    width: '80%',
                    marginBottom: '20px',
                }}
            >
                234 пациента включены в анализ (у 5 пациентов не было данных на
                12 неделе периода наблюдения)
            </h2>
            <Screen304Svg style={{ width: '80%' }} />
        </div>
    );

    const Info1 = (
        <Info>
            <h2>Источники и сокращения</h2>
            <p>
                1. ДИ - доверительный интервал;
                <br />
                <br />
                2. ДАК - даклатасвир;
                <br />
                <br />
                3. ГЦК - гепатоцеллюлярная карцинома
                <br />
                <br />
                4. ЛДВ - ледипасвир
                <br />
                <br />
                5. РБВ - рибавирин
                <br />
                <br />
                6. СОФ - софосбувил
                <br />
                <br />
                7. ВЕЛ - велпатасвир
                <br />
                <br />
                8. ХГС - хронический гепатит С
                <br />
                <br />
                9. СВО - стойкий вирусологический ответ
                <br />
                <br />
                10. Zhang W, et al. AASLD <span>2021. 962.</span>
            </p>
        </Info>
    );

    const Info2 = (
        <Info>
            <h2>Источники и сокращения</h2>
            <p>
                *На момент диагностики вирусологического прорыва у пациента
                регистрировались неопределяемые уровни препарата в крови. 1.
                СОФ/ВЕЛ - софосбувир/велпатасвир
                <br />
                <br />
                2. РБВ - рибавирин
                <br />
                <br />
                3. ГТ - генотип
                <br />
                <br />
                4. СВО - стойкий вирусологический ответ
                <br />
                <br />
                5. Curry MP, et al. N Engl J Med <span>2015;373:2618-28;</span>
                <br />
                <br />
                6. Charlthon M, et al. AASLD <span>2015; Abstract #LB-13</span>
            </p>
        </Info>
    );

    const Info3 = (
        <Info>
            <h2>Источники и сокращения</h2>
            <p>
                1. СОФ/ВЕЛ - софосбувир/велпатасвир;
                <br />
                <br />
                2. СВО - стойкий вирусологический ответ;
                <br />
                <br />
                3. РБВ - рибавирин;
                <br />
                <br />
                4. Шкала MELD - модель для оценки терминальной стадии
                заболеваний печени (предназначена для оценки относительной
                тяжести заболевания и прогноза жизни у пациентов с терминальной
                стадией печеночной недостаточности, ожидающих трансплантации
                печени;
                <br />
                <br />
                5. Curry MP, et al. N Engl J Med <span>2015;373:2618-28;</span>
                <br />
                <br />
                6. Charlthon M, et al. AASLD <span>2015; Abstract #LB-13</span>
            </p>
        </Info>
    );

    const Info4 = (
        <Info>
            <h2>Источники и сокращения</h2>
            <p>
                СОФ/ВЕЛ - софосбувир/велпатасвир;
                <br />
                <br />
                СВО - стойкий вирусологический ответ;
                <br />
                <br />
                РБВ - рибавирин;
                <br />
                <br />
                1. Curry MP, et al. N Engl J Med <span>2015;373:2618-28;</span>
                <br />
                <br />
                2. Charlthon M, et al. AASLD <span>2015; Abstract #LB-13</span>
            </p>
        </Info>
    );

    const updateInfo = (slide: number) => {
        setActiveInfo(slide);
    };

    const infoContent: JSX.Element[] = [Info1, Info2, Info3, Info4];

    const sliderContent: JSX.Element[] = [Slide1, Slide2, Slide3, Slide4];

    return (
        <Container style={{ zIndex: 8000, overflow: 'auto' }}>
            {modalActive
                ? infoContent[activeInfo] && (
                      <Popup active={modalActive} setActive={setModalActive}>
                          {infoContent[activeInfo]}
                      </Popup>
                  )
                : null}

            <Graphic
                marginTop={`${0}`}
                style={{
                    overflow: 'auto',
                }}
            >
                <Slider
                    content={sliderContent}
                    updateInfo={updateInfo}
                    startingSlide={0}
                />
            </Graphic>
        </Container>
    );
};

export default Screen30;
