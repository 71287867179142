import React from 'react';
import { Container } from '../Screen6/Screen6.styles';
import { IScreen } from '../../storage/types/app.types';
// import { ReactComponent as Screen34Svg } from './../../assets/images/svg/Screen34.svg';
import { ReactComponent as Screen34Svg } from './../../assets/images/svg/kratka.svg';
import { Graphic } from '../../components/Graphic/Graphic';
import { TitleStyled } from '../main.page.styles';

export const Screen34: React.FC<IScreen> = ({
    modalActive,
    setModalActive,
}) => {
    return (
        <Container style={{ zIndex: 8000 }}>
            <Graphic
                marginTop={`${0}`}
                style={{ backgroundColor: 'white', height: 'auto' }}
            >
                {/* <TitleStyled
                    style={{
                        fontSize: '20px',
                        lineHeight: '22px',
                    }}
                >
                    Кратка инструкция по медицинскому применению препарата
                    Эпклюза®.
                </TitleStyled>
                <h2
                    style={{
                        fontFamily: 'Seravek, sans-serif',
                        fontWeight: 700,
                        fontSize: 20,
                        lineHeight: '22px',
                        color: 'var(--blue)',
                        textAlign: 'center',
                        marginBottom: '10px',
                    }}
                >
                    Полная информация о препарате и безопасности его применения
                    содержится в Инструкции по медицинскому применению
                    лекарственного препарата Эпклюза®.
                </h2> */}
                <Screen34Svg />
            </Graphic>
        </Container>
    );
};
