import React, { useState } from 'react';
import { SidebarContainerStyled } from './sidebar.styles';
import { Slider } from '../Slider/Slider';
import { Graphic } from '../Graphic/Graphic';
import { ReactComponent as Screen35Svg } from '../../assets/images/svg/Screen35.svg';
import { ReactComponent as Screen36Svg } from '../../assets/images/svg/Screen36.svg';
import { ReactComponent as Screen37Svg } from '../../assets/images/svg/Screen37.svg';
import { ReactComponent as Screen38Svg } from '../../assets/images/svg/Screen38.svg';
import { Container } from '../../pages/Screen6/Screen6.styles';
import { setSelectedGraphic } from '../../storage/reducers/app.reducer';
import { useDispatch } from 'react-redux';
import { TitleStyled } from '../../pages/main.page.styles';
import { Popup } from '../Popup/Popup';
import { Info } from '../Popup/Popup.styles';
import { IScreen } from '../../storage/types/app.types';

export const Astral: React.FC<IScreen> = ({ modalActive, setModalActive }) => {
    const [activeInfo, setActiveInfo] = useState<number>(0);

    const Slide1 = (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}
        >
            <TitleStyled
                style={{
                    width: '60%',
                    fontSize: '32px',
                    lineHeight: '34px',
                }}
            >
                Режим СОФ/ВЕЛ изучался в программе исследований III фазы ASTRAL
                у 1408 пациентов с ХГС
            </TitleStyled>
            <Screen35Svg />
        </div>
    );
    const Slide2 = (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}
        >
            <TitleStyled
                style={{
                    width: '57%',
                    fontSize: '24px',
                    lineHeight: '26px',
                }}
            >
                Режим СОФ/ВЕЛ изучался в программе исследований III фазы ASTRAL
                у 1408 пациентов с ХГС
            </TitleStyled>
            <Screen36Svg />
        </div>
    );

    const Slide3 = (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}
        >
            <TitleStyled
                style={{
                    width: '60%',
                    fontSize: '23px',
                    lineHeight: '26px',
                }}
            >
                Международные данные в реальной практике подтвердили высокую
                эффективность режима СОФ/ВЕЛ 12 недель независимо от стадии
                фиброза и опыта терапии
            </TitleStyled>
            <Screen37Svg
                style={{
                    width: '80%',
                }}
            />
        </div>
    );

    const Slide4 = (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}
        >
            <TitleStyled
                style={{
                    width: '60%',
                    fontSize: '28px',
                    lineHeight: '32px',
                }}
            >
                Международные данные в реальной практике подтвердили высокую
                эффективность режима СОФ/ВЕЛ 12 недель независимо от стадии
                фиброза и опыта терапии
            </TitleStyled>
            <Screen38Svg />
        </div>
    );

    const Info1 = (
        <Info>
            <h2>Источники и сокращения</h2>
            <ol
                style={{
                    fontSize: '20px',
                    lineHeight: '24px',
                    marginLeft: '10px',
                }}
            >
                <li>ВГС - вирус гепатита С;</li>
                <li>ВИЧ - вирус иммунодефицита человека;</li>
                <li>ВЕЛ - велпатасвир;</li>
                <li>ГТ - генотип;</li>
                <li>нед. - неделя лечения;</li>
                <li>РБВ - рибавирин;</li>
                <li>СВО - стойкий вирусологический ответ</li>
                <li>СОФ- софосбувир;</li>
                <li>ЧТП - класс по шкале Чайлд-Таркотт-Пью;</li>
                <li>ЦП - цирроз печени</li>
                <li>
                    Feld, AASLD, 2015, LB-2. Feld JJ, et al. N Engl J Med. 2015;
                    Sulkowski, AASLD, 2015, 205. Foster GR, et al. New Engl J
                    Med. 2015; Mangla, AASLD, 2015, 249. Foster GR, et al. New
                    Engl J Med. 2015. Charlton, AASLD, 2015, LB-13. Curry MP, et
                    al. New Engl J Med. 2015. Wyles, EASL 2016, PS104
                </li>
            </ol>
        </Info>
    );

    const Info2 = (
        <Info>
            <h2>Источники и сокращения</h2>
            <ol
                style={{
                    fontSize: '20px',
                    lineHeight: '24px',
                    marginLeft: '10px',
                }}
            >
                <li>ГТ - генотип;</li>
                <li>ВГС - вирус гепатита С;</li>
                <li>ВЕЛ - велпатасвир;</li>
                <li>ВИЧ - вирус иммунодефицита человека;</li>
                <li>РБВ - рибавирин;</li>
                <li>ИП - ингибитор протеразы ВГС;</li>
                <li>СВО - стойкий вирусологический ответ;</li>
                <li>СОФ - софосбувир;</li>
                <li>ЧТП - класс по шкале Чайлд-Таркотт-Пью;</li>
                <li>
                    ITT анализ: анализ результатов всех рандомизированных
                    пациентов, то есть получивших, как минимум, одну дозу
                    препарата
                </li>
                <li>ПегИФН - пегилированный интреферон альфа;</li>
                <li>
                    1. Feld, AASLD, 2015, LB-2. Feld JJ, et al. N Engl J Med.
                    2015. DOI: 10.1056/NEJMoa1512610; Sulkowski, AASLD, 2015,
                    205. Foster GR, et al. New Engl J Med. 2015. DOI:
                    10.1056/NEJMoa1512612; Mangia, AASLD, 2015, 249. Foster GR,
                    et al. New Engl J Med. 2015. DOI: 10.1056/NEJMoa1512612;
                    Charlton, AASLD, 2015, LB-13. Curry MP, et al. New Engl J
                    Med.2015. DOI: 10.1056/NEJMoa1512614
                </li>
                <li>
                    *Р - показатель значимости различий в эффективности СОФ/ВЕЛ
                    и СОФ + РБВ
                </li>
            </ol>
        </Info>
    );

    const Info3 = (
        <Info>
            <h2>Источники и сокращения</h2>
            <p style={{ fontSize: '20px', lineHeight: '24px' }}>
                1. ВГС – вирус гепатита С;
                <br />
                <br />
                2. ГТ – генотип;
                <br />
                <br />
                3. ИП – ингибиторы протеазы;
                <br />
                <br />
                4. ПегИФН – пегинтерферон альфа;
                <br />
                <br />
                5. РБВ – рибавирин;
                <br />
                <br />
                6. СВО – стойкий вирусологический ответ;
                <br />
                <br />
                7. СО – стандартная ошибка
                <br />
                <br />
                8. Mangia et al. Liver International.{' '}
                <a
                    href="https://doi.org/10.1111/liv.14537"
                    target="_blank"
                    style={{ textDecorationColor: 'var(--teal)' }}
                >
                    <span>https://doi.org/10.1111/liv.14537</span>
                </a>
            </p>
        </Info>
    );

    const Info4 = (
        <Info>
            <h2>Источники и сокращения</h2>
            <p style={{ fontSize: '20px', lineHeight: '24px' }}>
                1. ВГС – вирус гепатита С;
                <br />
                <br />
                2. ЦП – цирроз печени;
                <br />
                <br />
                3. ГТ – генотип;
                <br />
                <br />
                4. ИП – ингибиторы протеазы;
                <br />
                <br />
                5. ПегИФН – пегинтерферон альфа;
                <br />
                <br />
                6. РБВ – рибавирин;
                <br />
                <br />
                7. СВО – стойкий вирусологический ответ;
                <br />
                <br />
                8. СО – стандартная ошибка
                <br />
                <br />
                9. Mangia et al. Liver International.{' '}
                <a
                    href="https://doi.org/10.1111/liv.14537"
                    target="_blank"
                    style={{ textDecorationColor: 'var(--teal)' }}
                >
                    <span>https://doi.org/10.1111/liv.14537</span>
                </a>
            </p>
        </Info>
    );

    const updateInfo = (slide: number) => {
        setActiveInfo(slide);
    };
    const sliderContent: JSX.Element[] = [Slide1, Slide2, Slide3, Slide4];

    const infoContent: JSX.Element[] = [Info1, Info2, Info3, Info4];

    return (
        <Container style={{ zIndex: 8000, overflow: 'auto' }}>
            {modalActive
                ? infoContent[activeInfo] && (
                      <Popup
                          active={modalActive}
                          setActive={setModalActive}
                          style={{ width: '830px', padding: '0 20px' }}
                      >
                          {infoContent[activeInfo]}
                      </Popup>
                  )
                : null}

            <Graphic marginTop={`${0}`} style={{ overflow: 'auto' }}>
                <div>
                    <Slider
                        content={sliderContent}
                        updateInfo={updateInfo}
                        startingSlide={0}
                    />
                </div>
            </Graphic>
        </Container>
    );
};

export const SidebarAstral = () => {
    const dispatch = useDispatch();

    return (
        <>
            <SidebarContainerStyled
                onClick={() =>
                    dispatch(
                        setSelectedGraphic({
                            index: 16,
                            Component: Astral,
                            title: '',
                            titleFontSize: 34,
                            text: '',
                            buttonText: '',
                            infoButton: {
                                Component: () => <div />,
                            },
                        })
                    )
                }
            >
                Подробнее про исследования ASTRAL
            </SidebarContainerStyled>
        </>
    );
};
