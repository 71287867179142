import styled from 'styled-components/macro';
import { rem } from '../../utils/rem';

export const StyledButton = styled.button`
    overflow: hidden;
    margin: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-family: Seravek, sans-serif;
    font-weight: 700;
    font-size: 36px;
    width: 300px;
    height: 300px;
    color: white;
    border-inline: 3px #002e6d;
    border-radius: 15px;
    background-color: var(--teal);
    border: none;
    z-index: ${(props) => (props ? props.zIndex : 'auto')};
    filter: ${(props) =>
        props.shadow
            ? 'drop-shadow(-5px -5px 10px #ffffff) drop-shadow(5px 5px 10px #c7cdd7)'
            : 'none'};
    cursor: pointer;
    :active {
        background-color: var(--blue);
        border-inline: none;
    }
`;
