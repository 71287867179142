import React, { useState } from 'react';
import { Container } from '../Screen6/Screen6.styles';
import { ReactComponent as Screen28Svg } from './../../assets/images/svg/Screen28.svg';
import { ReactComponent as Screen28PSvg } from './../../assets/images/svg/Screen28-p.svg';
import { Popup } from '../../components/Popup/Popup';
import { Graphic } from '../../components/Graphic/Graphic';
import { IScreen } from '../../storage/types/app.types';
import { TitleStyled } from '../main.page.styles';

const Screen28: React.FC<IScreen> = ({ modalActive, setModalActive }) => {
    const [activeInfo, setActiveInfo] = useState<number>(0);

    const Info1 = (
        <Graphic marginTop={'0'} style={{ paddingBottom: 0 }}>
            <h2 style={{ fontSize: 28, lineHeight: '34px', fontWeight: 700 }}>
                Исходные демографические характеристики
                в обобщенном анализе данных применения режима СОФ/ВЕЛ у пациентов с ХГС с компенсированным циррозом (F4, Чайлд-Пью A)<sub>1</sub>
            </h2>
            <Screen28PSvg style={{ width: '66rem' }} />
        </Graphic>
    );

    const infoContent: JSX.Element[] = [Info1];

    return (
        <Container style={{ zIndex: 8000, overflow: 'scroll' }}>
            {modalActive
                ? infoContent[activeInfo] && (
                      <Popup
                          active={modalActive}
                          setActive={setModalActive}
                          fullScreen
                      >
                          {infoContent[activeInfo]}
                      </Popup>
                  )
                : null}

            <Graphic
                marginTop={`${0}`}
                style={{
                    overflow: 'scroll',
                }}
            >
                <div>
                    <TitleStyled style={{ marginTop: '50px' }}>
                        Ретроспективный объединенный анализ данных 236 пациентов
                        с F3, принимавших
                        <br /> режим СОФ/ВЕЛ 12 недель в клинических
                        исследованиях ASTRAL-1, 2, 3, POLARIS-2, 3
                    </TitleStyled>
                    <Screen28Svg />
                </div>
            </Graphic>
        </Container>
    );
};

export default Screen28;
