import { Info } from '../../Popup/Popup.styles';

export const Screen11Info: React.FC = () => {
    return (
        <Info>
            <h2>Источники</h2>
            <p>
                Е.Б.Ярошенко , Э.З.Бурневич, Я.Г.Мойсюк, Роль вирусных гепатитов в развитии гептоцеллюлярной карциномы, Практическая онкология, <br/><span>Т. 9, № 4 – 2008</span>
            </p>
        </Info>
    );
};
