import styled from 'styled-components/macro';
import { rem } from '../../utils/rem';
import linesB from '../../assets/images/svg/footer_linesB.svg';
import linesC from '../../assets/images/svg/footer_linesC.svg';

export const Tools = styled.div`
    display: flex;
    position: relative;
    /* filter: drop-shadow(-5px -5px 10px #ffffff) */
    /* drop-shadow(5px 5px 10px #c7cdd7); */
`;
export const FooterButton = styled.div`
    font-family: Seravek, sans-serif;
    display: flex;
    flex-direction: ${(props) => (props.item.description ? 'column' : 'row')};
    gap: ${(props) => (props.item.description ? '6px' : '12px')};
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    height: 82px;
    font-weight: 400;
    font-size: 30px;
    line-height: 37px;
    color: var(--teal);
    box-sizing: border-box;
    border-right: 1px solid #f0f1f5;
    border-bottom: 3px solid
        ${(props) =>
            props.index === props.step.index
                ? '#1F3266'
                : 'var(--custom-gray)'};
    background-color: ${(props) =>
        props.index === props.step.index ? '#D3ECF2' : 'white'};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    &:first-child {
        border-radius: ${rem(15)} 0 0 ${rem(15)};
    }
    &:last-child {
        border-radius: 15px;
        border-right: none;
        margin-left: 16px;
    }
    &:nth-child(6) {
        border-radius: 0 15px 15px 0;
        border-right: none;
    }
    &:nth-child(7) {
        position: absolute;
        width: 200px;
        right: 130px;
        top: -88px;
        height: 62px;
        border-right: none;
        border-radius: 15px;
        text-align: center;
        ::after {
            z-index: -1;
            position: absolute;
            left: -220px;
            transform: scale(0.9);
            bottom: -42px;
            width: 4.758rem;
            height: 5.051rem;
            content: ${({ way }) =>
                way === 'b' ? `url(${linesB})` : `url(${linesC})`};
        }
    }

    @media screen and (max-width: 1024px) {
        font-size: 24px;
        gap: 5px !important;
        padding: 5px !important;
        &:nth-child(7) {
            top: -75px;
            ::after {
                bottom: -26px;
            }
        }
    }
`;

export const Description = styled.span`
    font-weight: 700;
    font-size: 13px;
    line-height: 12px;
    white-space: nowrap;
`;
