import { ReactComponent as Rectangle1 } from '../../assets/icons/svg/Rectangle1.svg';
import { ReactComponent as Rectangle2 } from '../../assets/icons/svg/Rectangle2.svg';
import React from 'react';
import { ReactComponent as Rectangle3 } from '../../assets/icons/svg/Rectangle3.svg';
import { ReactComponent as Rectangle4 } from '../../assets/icons/svg/Rectangle4.svg';
import { ReactComponent as Rectangle5 } from '../../assets/icons/svg/Rectangle5.svg';

export const SidebarContent4: React.FC = () => {
    return (
        <ul>
            <li>
                <div style={{ backgroundColor: '#A43838', borderRadius: '50%', width: 18, height: 18, flexShrink: 0 }} />
                Сосуды
            </li>
            <li>
                <div style={{ backgroundColor: '#E34E4E', borderRadius: '50%', width: 18, height: 18, flexShrink: 0 }} />
                Нормальная ткань — состояние паренхимы печени без патологических изменений
            </li>
            <li>
                <div style={{ backgroundColor: '#7B9EC2', borderRadius: '50%', width: 18, height: 18, flexShrink: 0 }} />
                Фиброзная (соединительная) ткань
            </li>
            <li>
                <div style={{ backgroundColor: '#6E517E', borderRadius: '50%', width: 18, height: 18, flexShrink: 0 }} />
                Нарушение архитектоники печени (ложные дольки)
            </li>
        </ul>
    );
};
