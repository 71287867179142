import styled from 'styled-components/macro';
import { ReactComponent as BloodCellWithLoupBg } from '../../assets/icons/svg/BloodCellWithLoupBg.svg';

export const HeaderButton = styled.button`
    box-shadow: -5px -5px 10px #ffffff, 5px 5px 10px #c7cdd7;
    font-family: Seravek, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    background: var(--teal);
    padding: 0 35px;
    height: 65px;
    border: none;
    border-radius: 15px;
    max-width: 300px;
    :active {
        background-color: var(--blue);
    }
`;

export const HeaderBackButton = styled.button`
    z-index: 1000;
    width: 65px;
    height: 65px;
    position: fixed;
    border: none;
    left: 25px;
    top: 25px;
    background: var(--teal);
    border-radius: 15px;
    :active {
        background-color: var(--blue);
    }
`;

export const HeaderLeftButton = styled.button`
    margin-top: 7px;
    position: absolute;
    width: 140px;
    height: 140px;
    border: none;
    background: var(--teal);
    border-radius: 50%;
    :active {
        background-color: var(--blue);
    }
    @media screen and (max-width: 1024px) {
        width: 120px;
        height: 120px;
    }
`;

export const BloodCellWithLoupBgStyled = styled(BloodCellWithLoupBg)`
    position: absolute;
    left: 10px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    clip-path: inset(-5px 0px -5px -5px);
    @media screen and (max-width: 1024px) {
        height: 134px;
        left: -5px;
    }
`;
