import React from 'react';
import instruction from './../../assets/images/svg/Instruction.png';
import { Container } from '../Screen6/Screen6.styles';
import { IScreen } from '../../storage/types/app.types';
import { Graphic } from '../../components/Graphic/Graphic';

export const Screen17: React.FC<IScreen> = ({
    modalActive,
    setModalActive,
}) => {
    return (
        <Container style={{ zIndex: 8000 }}>
            <Graphic marginTop={`${0}`} style={{ backgroundColor: 'white' }}>
                <img src={instruction} style={{ width: '100vw' }} />
            </Graphic>
        </Container>
    );
};
