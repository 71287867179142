import React, { useState } from 'react';
import { Slider } from '../../components/Slider/Slider';
import { ReactComponent as Slide1Svg } from './../../assets/images/svg/Slide1.svg';
import { ReactComponent as Slide2Svg } from './../../assets/images/svg/Slide2.svg';
import { ReactComponent as Slide3Svg } from './../../assets/images/svg/Slide3.svg';
import { Container } from '../Screen6/Screen6.styles';
import { TitleContainerStyled, TitleStyled } from '../main.page.styles';
import { Info } from '../../components/Popup/Popup.styles';
import { Popup } from '../../components/Popup/Popup';
import { Graphic } from '../../components/Graphic/Graphic';
import { IScreen } from '../../storage/types/app.types';

export const Screen15: React.FC<IScreen> = ({
    modalActive,
    setModalActive,
}) => {
    const [activeInfo, setActiveInfo] = useState<number>(0);

    const Slide1 = (
        <TitleContainerStyled>
            <TitleStyled style={{ marginTop: '20px' }}>
                ТАФ для профилатики ХГВ после трансплантации печени у пациентов
                <br />с ХПН (4-летние данные по безопасности и эффективности)
            </TitleStyled>
            <Slide1Svg style={{ width: '85vw' }} />
        </TitleContainerStyled>
    );
    const Slide2 = (
        <TitleContainerStyled>
            <TitleStyled>Почечные показатели</TitleStyled>
            <Slide2Svg style={{ width: '90vw', marginTop: '-20px' }} />
        </TitleContainerStyled>
    );
    const Slide3 = (
        <TitleContainerStyled>
            <TitleStyled>
                Изменения минеральной плотности костей (МПК) у <br />
                пациентов с разной степенью снижения рСКФ
            </TitleStyled>
            <Slide3Svg
                style={{ width: '85vw', marginTop: '-10px', marginLeft: 40 }}
            />
        </TitleContainerStyled>
    );

    const Info1 = (
        <Info>
            <h2>Источники</h2>
            <ol type="1">
                <li>ХГВ-хронический гепатит В;</li>
                <li>ХПН-хроническая почечная недостаточность;</li>
                <li>рСКФ — расчетная скорость клубочковой фильтрации;</li>
                <li>
                    CKD-EPI -Сотрудничество в области эпидемиологии хронических
                    заболеваний почек;
                </li>
                <li>ДНК ВГВ – ДНК вируса гепатита В;</li>
                <li>ТАФ – тенофовира алафенамид;</li>
                <li>АЛТ - аланин-аминотрансфераза;</li>
                <li>
                    TND -Target Not Detected, молекула-мишень не обнаружена;
                </li>
                <li>Gane EJ, et al. AASLD 2021. 803</li>
            </ol>
        </Info>
    );
    const Info2 = (
        <Info>
            <h2>Источники</h2>
            <ol type="1">
                <li>β2M, β2-микроглобулин,;</li>
                <li>рСКФ — расчетная скорость клубочковой фильтрации;</li>
                <li>
                    CKD-EPI -Сотрудничество в области эпидемиологии хронических
                    заболеваний почек;
                </li>
                <li>ТАФ – тенофовира алафенамид;</li>
                <li>ТДФ - Тенофовира дизопроксил фумарат;</li>
                <li>Gane EJ, et al. AASLD 2021. 803</li>
            </ol>
        </Info>
    );

    const Info3 = (
        <Info style={{ paddingTop: 0, paddingBottom: 0 }}>
            <h2 style={{ fontSize: 24 }}>Источники</h2>
            <ol type="1">
                <li>рСКФ — расчетная скорость клубочковой фильтрации;</li>
                <li>
                    CKD-EPI — сотрудничество в области эпидемиологии хронических
                    заболеваний почек;
                </li>
                <li>ТАФ — тенофовира алафенамид;</li>
                <li>ТДФ — тенофовира дизопроксила фумарат;</li>
                <li>Gane EJ, et al. AASLD 2021. 803</li>
            </ol>
        </Info>
    );

    const updateInfo = (slide: number) => {
        setActiveInfo(slide);
    };

    const sliderContent: JSX.Element[] = [Slide1, Slide2, Slide3];
    const infoContent: JSX.Element[] = [Info1, Info2, Info3];

    return (
        <Container style={{ zIndex: 8000 }}>
            {modalActive ? (
                <Popup active={modalActive} setActive={setModalActive}>
                    {infoContent[activeInfo]}
                </Popup>
            ) : null}

            <Graphic marginTop={`${0}`}>
                <div>
                    <Slider
                        content={sliderContent}
                        updateInfo={updateInfo}
                        startingSlide={0}
                    />
                </div>
            </Graphic>
        </Container>
    );
};
