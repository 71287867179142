import React from 'react';

export const SidebarContent2: React.FC = () => {
    return (
        <ul>
            <li>
                <div style={{ backgroundColor: '#A43838', borderRadius: '50%', width: 18, height: 18, flexShrink: 0 }} />
                Сосуды
            </li>
            <li>
                <div style={{ backgroundColor: '#E34E4E', borderRadius: '50%', width: 18, height: 18, flexShrink: 0 }} />
                Нормальная ткань — состояние паренхимы печени без патологических изменений
            </li>
            <li>
                <div style={{ backgroundColor: '#7B9EC2', borderRadius: '50%', width: 18, height: 18, flexShrink: 0 }} />
                Фиброзная (соединительная) ткань
            </li>
            <li>
                <div style={{ backgroundColor: '#F4E363', borderRadius: '50%', width: 18, height: 18, flexShrink: 0 }} />
                Желчные протоки
            </li>
        </ul>
    );
};
