import React, { useState } from 'react';
import { SidebarGCKStyled } from './sidebar.styles';
import { useDispatch } from 'react-redux';
import { setSelectedGraphic } from '../../storage/reducers/app.reducer';
import { TitleStyled } from '../../pages/main.page.styles';
import { ReactComponent as Screen39Svg } from '../../assets/images/svg/Screen39.svg';
import { ReactComponent as Screen40Svg } from '../../assets/images/svg/Screen40.svg';
import { Container } from '../../pages/Screen6/Screen6.styles';
import { Graphic } from '../Graphic/Graphic';
import { Popup } from '../Popup/Popup';
import { Info } from '../Popup/Popup.styles';
import { Slider } from '../Slider/Slider';

export const GCK = ({
    modalActive,
    setModalActive,
}: {
    modalActive: any;
    setModalActive: any;
}) => {
    const [activeInfo, setActiveInfo] = useState(0);

    const Slide1 = (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}
        >
            <TitleStyled
                style={{
                    width: '60%',
                    fontSize: '32px',
                    lineHeight: '34px',
                }}
            >
                Ожидаемые тенденции заболеваемости гепатоцеллюлярной карциномой
                с 2015 по 2030 год
            </TitleStyled>
            <Screen39Svg />
        </div>
    );

    const Slide2 = (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}
        >
            <TitleStyled
                style={{
                    width: '60%',
                    fontSize: '32px',
                    lineHeight: '34px',
                }}
            >
                Риск развития злокачественных новообразований при ХГВ или ХГС,
                по сравнению с активными курильщиками
            </TitleStyled>
            <Screen40Svg />
        </div>
    );

    const Info1 = (
        <Info>
            <h2>Источники</h2>
            <ol
                style={{
                    fontSize: '20px',
                    lineHeight: '24px',
                    marginLeft: '10px',
                }}
            >
                <li>
                    *Пунктирные линии соответствуют ожидаемой заболеваемости
                    (всего в мире) с 2021 по 2030 год.
                </li>
                <li>
                    <span>ГЦК</span> – гепатоцеллюлярная карцинома;
                </li>
                <li>
                    <span>ХГВ</span> – хронический гепатит В;
                </li>
                <li>
                    <span>ХГС</span> – хронический гепатит С.
                </li>
                <li>
                    <span>
                        Razavi-Shearer D, et al. EASL 2023. Постер №TOP-096
                    </span>
                </li>
            </ol>
        </Info>
    );

    const Info2 = (
        <Info>
            <h2>Источники</h2>
            <ol
                style={{
                    fontSize: '20px',
                    lineHeight: '24px',
                    marginLeft: '10px',
                }}
            >
                <li>
                    *ОШ относится к сравнению ассоциаций между курением и
                    развитием злокачественных опухолей, с одной стороны, и
                    ассоциацией между наличием ХГВ и ХГС и развитием ГЦК.
                </li>
                <li>
                    <span>aHR</span> – отношение рисков после коррекции; aOR –
                    отношение шансов после коррекции;
                </li>
                <li>
                    <span>BsAg+</span> - положительный статус по поверхностному
                    антигену вируса гепатита B;
                </li>
                <li>
                    <span>ГЦК</span> – гепатоцеллюлярная карцинома;
                </li>
                <li>
                    <span>ОШ</span> – отношение шансов;
                </li>
                <li>
                    <span>ХГС</span> – хронический гепатит С.
                </li>
                <li>
                    <span>Razavi H, et al. EASL 2023. Постер №FRI-212</span>
                </li>
            </ol>
        </Info>
    );

    const updateInfo = (slide: number) => {
        setActiveInfo(slide);
    };

    const sliderContent: JSX.Element[] = [Slide1, Slide2];

    const infoContent: JSX.Element[] = [Info1, Info2];

    return (
        <Container style={{ zIndex: 8000, overflow: 'auto' }}>
            {modalActive
                ? infoContent[activeInfo] && (
                      <Popup
                          active={modalActive}
                          setActive={setModalActive}
                          style={{ width: '830px', padding: '0 20px' }}
                      >
                          {infoContent[activeInfo]}
                      </Popup>
                  )
                : null}

            <Graphic marginTop={`${0}`} style={{ overflow: 'auto' }}>
                <div>
                    <Slider
                        content={sliderContent}
                        updateInfo={updateInfo}
                        startingSlide={0}
                    />
                </div>
            </Graphic>
        </Container>
    );
};

export const SidebarGCK = () => {
    const dispatch = useDispatch();

    return (
        <SidebarGCKStyled
            onClick={() =>
                dispatch(
                    setSelectedGraphic({
                        index: 16,
                        Component: GCK,
                        title: '',
                        titleFontSize: 34,
                        text: '',
                        buttonText: '',
                        infoButton: {
                            Component: () => <div />,
                        },
                    })
                )
            }
        >
            Риски и тенденции <br /> в развитии ГЦК
        </SidebarGCKStyled>
    );
};
