import styled from 'styled-components/macro';

export const Container = styled.div`
    pointer-events: none;
    background-color: var(--custom-gray);
    box-sizing: border-box;
    max-width: 90vw;
    margin: auto;
  
`;

export const Graph13Img = styled.div`
    margin-top: -12rem;
    margin-bottom: 4rem;
`;

export const Graph13Desc = styled.div`
    margin-top: -140px;
`;
