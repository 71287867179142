import { Graphic } from '../../components/Graphic/Graphic';
import { ReactComponent as GraphicSVG09 } from '../../assets/images/svg/Screen09.svg';
import { ReactComponent as GraphicSVG092 } from '../../assets/images/svg/Screen6-2.svg';
import { ReactComponent as GraphicSVG24 } from '../../assets/images/svg/Screen24.svg';
import React, { useState } from 'react';
import { Container } from './Screen6.styles';
import { Popup } from '../../components/Popup/Popup';
import { Screen23 } from '../Screen23/Screen23';
import { IScreen } from '../../storage/types/app.types';
import { Slider } from '../../components/Slider/Slider';
import { TitleContainerStyled, TitleStyled } from '../main.page.styles';

const screen09Style = {
    width: '68rem',
};

export const Screen6: React.FC<IScreen> = ({ modalActive, setModalActive }) => {
    const [activeInfo, setActiveInfo] = useState<number>(0);

    const updateInfo = (slide: number) => {
        setActiveInfo(slide);
    };

    const Slide1 = (
        <TitleContainerStyled>
            <TitleStyled style={{ marginTop: '14px' }}>
                Вирусологическая супрессия и нормализация уровня АЛТ через 5лет
            </TitleStyled>
            <GraphicSVG09 style={screen09Style} />
        </TitleContainerStyled>
    );
    const Slide2 = (
        <TitleContainerStyled>
            <GraphicSVG092 style={screen09Style} />
        </TitleContainerStyled>
    );

    const info1 = <Screen23 />;
    const info2 = (
        <Graphic marginTop={'0'} style={{ paddingBottom: 0 }}>
            <GraphicSVG24 style={{ width: '75vw', marginTop: '40px' }} />
        </Graphic>
    );

    const infoContent: JSX.Element[] = [info1, info2];

    return (
        <Container>
            <Graphic marginTop={`${20}`} zIndex={1000}>
                <Slider
                    content={[Slide1, Slide2]}
                    startingSlide={0}
                    updateInfo={updateInfo}
                />
            </Graphic>
            {modalActive ? (
                <Popup
                    active={modalActive}
                    setActive={setModalActive}
                    fullScreen
                >
                    {infoContent[activeInfo]}
                </Popup>
            ) : null}
        </Container>
    );
};
