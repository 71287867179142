import { useEffect, useState } from 'react';
import { Container, Item, SliderButton, Tools } from './Slider.styles';
import { ReactComponent as Back } from '../../assets/icons/svg/Back.svg';
import { ReactComponent as Next } from '../../assets/icons/svg/Next.svg';

interface ISlider {
    content: JSX.Element[];
    updateInfo?: (activeSlide: number) => void;
    startingSlide: number;
}

export const Slider: React.FC<ISlider> = ({
    content,
    updateInfo,
    startingSlide,
}) => {
    const [activeSlide, setActiveSlide] = useState<number>(startingSlide);
    const count = content.length;

    const handlePrev = () => {
        if (activeSlide > 0) setActiveSlide(activeSlide - 1);
    };

    const handleNext = () => {
        if (activeSlide < count - 1) setActiveSlide(activeSlide + 1);
    };

    useEffect(() => {
        if (updateInfo) updateInfo(activeSlide);
    }, [activeSlide]);

    return (
        <div>
            {content[activeSlide]}
            <Tools>
                <SliderButton
                    onClick={handlePrev}
                    disabled={activeSlide === 0}
                    left
                >
                    <Back />
                </SliderButton>
                <SliderButton
                    onClick={handleNext}
                    disabled={activeSlide === count - 1}
                    right
                >
                    <Next />
                </SliderButton>
            </Tools>
        </div>
    );
};
