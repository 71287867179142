import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { Scene } from '../../webgl/scenes/mainScene';
import { useDispatch, useSelector } from 'react-redux';
import {
    currentStepSelector,
    setStep,
} from '../../storage/reducers/app.reducer';
import HdrFile from '../../webgl/scenes/room.hdr';

const _MainComponent = ({ selectedStep, type }) => {
    const dispatch = useDispatch();
    dispatch(setStep(selectedStep));
    const _mount = React.useRef(null);
    const _img = React.useRef(null);
    const value = useSelector(currentStepSelector);
    const indexPage = value.index;

    let img;
    if (selectedStep >= 1 && selectedStep <= 5) {
        img = require(`../../assets/images/main_content_img/f${selectedStep}.png`);
    }
    const onPageChange = new CustomEvent('onpagechange', { detail: indexPage });
    const onTypeChange = new CustomEvent('ontypechange', { detail: type });

    useEffect(() => {
        window.dispatchEvent(onPageChange);
    }, [indexPage]);

    useEffect(() => {
        window.dispatchEvent(onTypeChange);
    }, [type]);

    useEffect(() => {
        if (_mount.current) {
            new Scene(
                _mount.current,
                selectedStep,
                type,
                _img.current,
                HdrFile
            );
        }
    }, [_mount, _img]);

    return (
        <div className="openglWrapper">
            <div id="gradientHolder"></div>
            <div className={styles.wrapperImg}>
                {/*{img && (*/}
                {/*    <img*/}
                {/*        ref={_img}*/}
                {/*        src={img.default}*/}
                {/*        width="220px"*/}
                {/*        height="120px"*/}
                {/*        alt="img"*/}
                {/*    />*/}
                {/*)}*/}
            </div>
            <div ref={_mount} />
        </div>
    );
};

export default _MainComponent;
