import clsx from 'clsx';
import React from 'react';
import styles from './app.module.css';
import { useHeight } from './hooks/utils.hooks';
import Main from './components/main/main';
import { GlobalStyle } from './app.styles';
import { useSelector } from 'react-redux';
import { waySelector } from './storage/reducers/app.reducer';

const App: React.FC = () => {
    const height = useHeight();
    const way = useSelector(waySelector);

    return (
        <>
            <GlobalStyle mainColor={way?.mainColor} />
            <div style={{ height }} className={clsx(styles.root)}>
                <Main />
            </div>
        </>
    );
};

export { App };
