import { Info } from '../../Popup/Popup.styles';

export const Screen10Info: React.FC = () => {
    return (
        <Info>
            <p>
                <h2>Источники</h2>
                Хубутия М.Ш., Восканян С.Э., Сюткин В.Е., Чуланов В.П.,
                Новрузбеков М.С., Пасечников В.Д. и др. Трансплантология.
                2020;12(3):231–244.
                <br />
                <br />
                <a
                    href="https://grls.rosminzdrav.ru/Grls_View_v2.aspx?routingGuid=73e28aae-5de5-48c7-89b5-3a82fc33f95d&t="
                    target="_blank"
                    style={{ textDecorationColor: 'var(--teal)' }}
                >
                    <span>
                        https://grls.rosminzdrav.ru/Grls_View_v2.aspx?routingGuid=73e28aae-5de5-48c7-89b5-3a82fc33f95d&t=
                    </span>
                </a>
                <br />
                Инструкция по медицинскому применению препарата Эпклюза(R) РУ
                ЛП-№(000948)-(РГ-RU)
            </p>
        </Info>
    );
};
