import styles from './styles.module.scss';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import {
    appSelector,
    currentStepSelector,
    setModelType,
    setSelectedGraphic,
    waySelector,
} from '../../storage/reducers/app.reducer';
import {
    BloodCellWithLoupBgStyled,
    HeaderLeftButton,
} from './MainHeaderNav.styles';
import { ReactComponent as ChevronBack } from '../../assets/icons/svg/ChevronBack.svg';
import { ReactComponent as ChevronNext } from '../../assets/icons/svg/ChevronNext.svg';
import { ReactComponent as BloodCellWithLoup } from '../../assets/icons/svg/BloodCellWithLoup.svg';
import { ReactComponent as InstructionIcon } from '../../assets/icons/svg/Instruction.svg';
import { ReactComponent as ProfileIcon } from '../../assets/icons/svg/Profile.svg';
import { ReactComponent as PointIcon } from '../../assets/icons/svg/Point.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/svg/Info.svg';
import { IScreen } from '../../storage/types/app.types';
import { Screen34 } from '../../pages/Screen34/Screen34';

interface IMainHeaderNav {
    modalActive: boolean;
    setModalActive: (value: boolean) => void;
    graphicModalActive: boolean;
    setGraphicModalActive: (value: boolean) => void;
    selectedStep?: any;
    type?: string;
}

const MainHeaderNav: React.FC<IMainHeaderNav> = ({
    modalActive,
    setModalActive,
    graphicModalActive,
    setGraphicModalActive,
}) => {
    const app = useSelector(appSelector);
    const dispatch = useDispatch();
    const way = useSelector(waySelector);
    const step = useSelector(currentStepSelector);
    const modelType = app.selectedModelType;

    const handleHeaderButtonClick = (
        index: number,
        component: React.FC<IScreen>,
        text: string,
        title: string | undefined,
        titleFontSize: number | undefined,
        buttonText: string,
        Component: React.FC,
        hideTitleOnInfo?: boolean
    ): void => {
        app.selectedGraphic && app.selectedGraphic.index === index
            ? dispatch(setSelectedGraphic(null))
            : dispatch(
                  setSelectedGraphic({
                      index: index,
                      Component: component,
                      title: title,
                      titleFontSize,
                      text: text,
                      buttonText: buttonText,
                      infoButton: {
                          Component: Component,
                      },
                      hideTitleOnInfo,
                  })
              );
    };

    const handleCloseGraphic = () => {
        dispatch(setSelectedGraphic(null));
    };

    const handleOpenLiver = () => {
        dispatch(setModelType('liver'));
    };

    const handleOpenCube = () => {
        dispatch(setModelType('cube'));
    };

    const handleModalClick = () => {
        modalActive ? setModalActive(false) : setModalActive(true);
    };

    const handleGraphicModalClick = () => {
        if (app.selectedGraphic?.buttonText === 'Эксклюза') {
            handleHeaderButtonClick(
                17,
                Screen34,
                '',
                '',
                undefined,
                'Эксклюза инструкция',
                () => <div />
            );
        }
        return graphicModalActive
            ? setGraphicModalActive(false)
            : setGraphicModalActive(true);
    };

    return (
        <div className={styles.container}>
            {modelType === 'liver' &&
            !app.selectedGraphic &&
            step!.index < 6 ? (
                <>
                    <BloodCellWithLoupBgStyled />
                    <HeaderLeftButton onClick={handleOpenCube}>
                        <BloodCellWithLoup />
                    </HeaderLeftButton>
                </>
            ) : null}
            {modelType === 'cube' && !app.selectedGraphic && step!.index < 6 ? (
                <>
                    <BloodCellWithLoupBgStyled />
                    <HeaderLeftButton onClick={handleOpenLiver}>
                        <ChevronBack />
                    </HeaderLeftButton>
                </>
            ) : null}
            <header
                className={clsx(
                    styles.header,
                    app.selectedGraphic && styles.header_hidden,
                    step?.index !== 6 &&
                        step?.index !== 7 &&
                        styles.with_change_btn
                )}
            >
                {!app.selectedGraphic && (
                    <div className={styles['step-btn-container']}>
                        {step?.headerButtons?.map((btn) => (
                            <div
                                className={styles['step-btn']}
                                onClick={() =>
                                    handleHeaderButtonClick(
                                        btn.index,
                                        btn.Component,
                                        btn.text,
                                        btn.title,
                                        btn.titleFontSize,
                                        btn.buttonText!,
                                        btn.infoButton.Component,
                                        btn.hideTitleOnInfo
                                    )
                                }
                            >
                                <span
                                    style={{
                                        display:
                                            step.index === 4
                                                ? 'inline'
                                                : 'flex',
                                    }}
                                >
                                    {btn.text}
                                    <span className={styles['blue']}>
                                        {step.index === 4
                                            ? ` ${btn.secondaryText}`
                                            : btn.secondaryText}
                                    </span>
                                </span>
                                <ChevronNext />
                            </div>
                        ))}
                    </div>
                )}
                {app.selectedGraphic && (
                    <button
                        className={clsx(styles.btn)}
                        onClick={handleCloseGraphic}
                    >
                        <ChevronBack />
                    </button>
                )}
                {app.selectedGraphic?.hideTitleOnInfo &&
                app.graphicModal ? null : (
                    <span
                        className={styles.title}
                        style={{
                            fontSize:
                                app.selectedGraphic?.titleFontSize || '38px',
                        }}
                    >
                        {app.selectedGraphic?.title ?? step?.title}
                    </span>
                )}
                <div className={styles.wrapper}>
                    {app.selectedGraphic?.buttonText !==
                        'Вемлиди®. Профили пациентов.' &&
                        app.selectedGraphic?.buttonText !== 'Эксклюза' &&
                        app.selectedGraphic?.buttonText !==
                            'Эксклюза инструкция' && (
                            <button
                                className={clsx(styles.hepatitis, styles.btn)}
                                onClick={() => {
                                    if (!step) {
                                        return;
                                    }

                                    handleHeaderButtonClick(
                                        16,
                                        step.Profile,
                                        '',
                                        '',
                                        undefined,
                                        way?.key === 'b'
                                            ? 'Вемлиди®. Профили пациентов.'
                                            : 'Эксклюза',
                                        () => <div />
                                    );
                                }}
                            >
                                {way?.key === 'b' && (
                                    <ProfileIcon
                                        style={{ marginLeft: '-5px' }}
                                    />
                                )}
                                {way?.key === 'c' && (
                                    <PointIcon style={{ marginTop: '-4px' }} />
                                )}
                            </button>
                        )}
                    {app.selectedGraphic?.buttonText !==
                        'Инструкция по применению' &&
                        app.selectedGraphic?.buttonText !== 'Эксклюза' &&
                        app.selectedGraphic?.buttonText !==
                            'Эксклюза инструкция' && (
                            <button
                                className={clsx(styles.btn)}
                                onClick={() => {
                                    if (!step) {
                                        return;
                                    }

                                    handleHeaderButtonClick(
                                        17,
                                        step.Instruction,
                                        '',
                                        '',
                                        undefined,
                                        way?.key === 'b'
                                            ? 'Инструкция по применению'
                                            : 'Эксклюза инструкция',
                                        () => <div />
                                    );
                                }}
                            >
                                <InstructionIcon />
                            </button>
                        )}
                    {app.selectedGraphic?.buttonText !==
                        'Эксклюза инструкция' && (
                        <button
                            className={clsx(styles.btn)}
                            onClick={
                                app.selectedGraphic
                                    ? handleGraphicModalClick
                                    : handleModalClick
                            }
                        >
                            <InfoIcon />
                        </button>
                    )}
                </div>
            </header>
        </div>
    );
    // else return <div />;
};

export default MainHeaderNav;
