import { Info } from '../../Popup/Popup.styles';
import { useSelector } from 'react-redux';
import { appSelector } from '../../../storage/reducers/app.reducer';

export const ScreenC2Info: React.FC = () => {
    const app = useSelector(appSelector);

    if (app.selectedModelType === 'cube') {
        return (
            <Info>
                <h2>Источники</h2>
                <p>
                    Изображение адаптировано из: Andrés Duarte-Rojo, José
                    Trinidad Altamirano, Jordan J. Feld,Noninvasive markers of
                    fibrosis: key concepts for improving accuracy in daily
                    clinical practice.Annals of Hepatology, Volume 11, Issue 4,
                    2012, Pages 426-439,
                    <br />
                    <br />
                    <a
                        href="https://doi.org/10.1016/S1665-2681(19)31456-5"
                        target="_blank"
                        style={{ textDecorationColor: 'var(--teal)' }}
                    >
                        <span>
                            https://doi.org/10.1016/S1665-2681(19)31456-5
                        </span>
                    </a>
                </p>
            </Info>
        );
    }

    return (
        <Info>
            <h2>Источники</h2>
            <p>
                EASL CPG HBV. J Hepatol <span>2017;67:370–98</span>
                <br />
                <br />
                *Данные Регистра больных вирусным гепатитом РОСПОТРЕБНАДЗОРА РВ{' '}
                <span>(2019 год), В.П. Чуланов</span>
            </p>
        </Info>
    );
};
