import React, { useState } from 'react';
import { Button, SidebarBlock, SidebarContent } from './Sidebar.styles';
import { ReactComponent as ChevronBottom } from '../../assets/icons/svg/ChevronBottom.svg';
import { ReactComponent as ChevronTop } from '../../assets/icons/svg/ChevronTop.svg';

export const Sidebar: React.FC = ({ children }) => {
    const [sidebarActive, setSidebarActive] = useState<boolean>(false);

    return (
        <SidebarBlock onClick={() => setSidebarActive(!sidebarActive)}>
            <Button>
                <span>Легенда</span> {sidebarActive ? <ChevronBottom /> : <ChevronTop />}
            </Button>
            {sidebarActive && <SidebarContent>{children}</SidebarContent>}
        </SidebarBlock>
    );
};
