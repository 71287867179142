import React from 'react';
import instruction from './../../assets/images/InstructionC.png';
import { Container } from '../Screen6/Screen6.styles';
import { IScreen } from '../../storage/types/app.types';
import { Graphic } from '../../components/Graphic/Graphic';
import { InstructionContainerStyled } from '../main.page.styles';

export const Screen33: React.FC<IScreen> = ({
    modalActive,
    setModalActive,
}) => {
    return (
        <Container style={{ zIndex: 8000 }}>
            <Graphic marginTop={`${0}`} style={{ backgroundColor: 'white' }}>
                <InstructionContainerStyled>
                    <img src={instruction} style={{ width: '100vw' }} />
                </InstructionContainerStyled>
            </Graphic>
        </Container>
    );
};
