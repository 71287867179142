import styled from 'styled-components/macro';
import { rem } from '../../utils/rem';

export const Container = styled.div`
    z-index: 10000;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 46, 109, 0.6);
    opacity: ${(props) => (props.active ? '100%' : '0%')};
    pointer-events: ${(props) => (props.active ? 'all' : 'none')};
    transition-duration: 0.5s;
`;

export const Content = styled.div`
    position: fixed;
    height: ${(props) => (props.fullScreen ? '100vh' : 'auto')};
    width: ${(props) => (props.fullScreen ? '100vw' : '720px')};
    padding: 20px;
    border-radius: 15px;
    background-color: var(--custom-gray);
    margin: ${rem(56)};
    font-family: Seravek, sans-serif;
    font-size: ${rem(24)};
    color: var(--blue);
    h2 {
        font-size: ${rem(40)};
        font-weight: 400;
        text-align: center;
        line-height: 49px;
        margin-top: 0;
        margin-right: 3rem;
        margin-left: 3rem;
    }
    h3 {
        font-size: ${rem(32)};
        font-weight: 400;
        text-align: center;
        line-height: 49px;
        margin-top: 0;
        margin-right: 3rem;
        margin-left: 3rem;
    }
    ol {
        margin-block-start: 0;
        li {
            margin-bottom: 20px;
        }
    }

    .scrollbar {
        margin-left: 30px;
        float: left;
        height: 83vh;
        overflow-y: auto;
        margin-bottom: 25px;
        padding-right: 1rem;
    }

    .scrollbar::-webkit-scrollbar-track {
        margin-top: 100px;
        -webkit-box-shadow: inset -5px -5px 10px #ffffff,
            inset 5px 5px 10px #c7cdd7;
        border-radius: 15px;
        background-color: #f5f5f5;
    }

    .scrollbar::-webkit-scrollbar {
        width: 9px;
    }

    .scrollbar::-webkit-scrollbar-thumb {
        border-radius: 9px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: var(--blue);
    }
`;

export const CloseBtn = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    color: #00acc8;
    background: none;
    border: none;
    position: absolute;
    right: ${rem(28)};
    top: ${rem(28)};
`;

export const Info = styled.div`
    padding: 16px 38px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
    h2 {
        justify-self: center;
        align-self: center;
        font-weight: 700;
        font-size: 30px;
        line-height: 37px;
    }
    ol {
        list-style: none;
        li {
            width: 100%;
            padding: 0;
            margin: 0;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            word-break: break-word;
            margin-bottom: 10px;
            span {
                color: var(--teal);
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    p {
        width: 100%;
        padding: 0;
        margin: 0;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        word-break: break-all;

        span {
            color: var(--teal);
        }
    }
`;
