import styled from 'styled-components/macro';

export const SidebarContainerStyled = styled.div`
    position: absolute;
    top: 190px;
    left: 20px;
    background-color: #fff;
    width: 310px;
    padding: 14px 13px;
    border-radius: 20px;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    font-family: 'Seravek', sans-serif;
    font-style: normal;
    font-weight: 700;
    color: var(--blue);
    z-index: 2;
`;

export const SidebarGCKStyled = styled.div`
    position: absolute;
    top: 280px;
    left: 20px;
    background-color: #fff;
    width: 310px;
    padding: 14px 13px;
    border-radius: 20px;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    font-family: 'Seravek', sans-serif;
    font-style: normal;
    font-weight: 700;
    color: var(--blue);
    z-index: 2;
`;

export const SidebarContainerCubeStyled = styled.div`
    position: absolute;
    top: 368px;
    right: 20px;
    background-color: #fff;
    width: 240px;
    box-shadow: 0px 3.40541px 3.40541px rgba(0, 0, 0, 0.05);
    border-radius: 17px;
    overflow: hidden;
    color: var(--blue);
    z-index: 2;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1024px) {
        top: 320px;
    }
`;

export const SidebarText = styled.div`
    text-align: left;
    font-size: 14px;
    line-height: 16px;
    font-family: 'Seravek', sans-serif;
    font-style: normal;
    font-weight: 700;
    padding: 10px 16px;
`;

export const ImageContainer = styled.div`
    display: flex;
    height: 90px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

export const SidebarPercentsStyled = styled.div`
    position: absolute;
    top: 340px;
    height: 240px;
    left: 20px;
    background-color: #fff;
    width: 240px;
    box-shadow: 0px 3.40541px 3.40541px rgba(0, 0, 0, 0.05);
    border-radius: 17px;
    overflow: hidden;
    color: var(--blue);
    z-index: 2;
    display: flex;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 10px;
`;

export const SidebarPercentsText = styled.div`
    font-family: 'Seravek', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #4e5457;
    margin-top: 10px;
`;
