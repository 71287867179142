import styled from 'styled-components/macro';
import { rem } from '../../utils/rem';

export const Item = styled.div`
`;

export const SvgContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const SliderImg = styled.div`
    margin-top: ${rem(-60)};
    margin-bottom: ${rem(24)};
`;

export const SliderInfo = styled.div`
    margin-top: ${rem(16)};
    display: flex;
    justify-content: space-evenly;
`;

export const Container = styled.div`
    position: relative;
    height: 60rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
    border-radius: 15px;
    width: 91rem;
    margin: auto;
    z-index: 100;
    background-color: var(--custom-gray);
`;

export const SliderButton = styled.button`
    z-index: 9000;
    pointer-events: all;
    background-color: var(--teal);
    position: absolute;
    right: 10px;
    left: ${(props) => props.left && '10px'};
    border-radius: 50%;
    width: 65px;
    height: 65px;
    border: none;
    margin: ${rem(20)};
    :disabled {
        opacity: 0;
    }
    :active {
        background-color: var(--yellow);
        color: var(--blue);
    }

    svg {
        position: relative;
    }
`;

export const Tools = styled.div`
    position: fixed;
    bottom: 8rem;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -11rem;
`;
