import React, { useState } from 'react';
import { Container } from '../Screen6/Screen6.styles';
import { ReactComponent as Screen31Svg } from './../../assets/images/svg/Screen31.svg';
import { ReactComponent as Screen31PSvg } from './../../assets/images/svg/Screen31-p.svg';
import { Popup } from '../../components/Popup/Popup';
import { Graphic } from '../../components/Graphic/Graphic';
import { IScreen } from '../../storage/types/app.types';
import { TitleStyled } from '../main.page.styles';
import { Info } from '../../components/Popup/Popup.styles';

const Screen31: React.FC<IScreen> = ({ modalActive, setModalActive }) => {
    const [activeInfo, setActiveInfo] = useState<number>(0);

    const Info1 = (
        <Info>
            <h2>Источники и сокращения</h2>
            <p>
                ПППД — противовирусные препараты прямого действия;
                <br />
                <br />
                ГЦК — гепатоцеллюлярная карцинома
                <br />
                <br />
                1. EASL recommendations on treatment of hepatitis C: Final
                update of the series☆. J Hepatol. 2020 Nov;73(5):1170-1218. doi:
                10.1016/j.jhep.2020.08.018.
            </p>
        </Info>
    );

    const infoContent: JSX.Element[] = [Info1];

    return (
        <Container style={{ zIndex: 8000, overflow: 'scroll' }}>
            {modalActive
                ? infoContent[activeInfo] && (
                      <Popup active={modalActive} setActive={setModalActive}>
                          {infoContent[activeInfo]}
                      </Popup>
                  )
                : null}

            <Graphic
                marginTop={`${0}`}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    overflow: 'scroll',
                    padding: '10rem 2em 0',
                }}
            >
                <TitleStyled
                    style={{
                        marginTop: '30px',
                        fontSize: '22px',
                        lineHeight: '28px',
                    }}
                >
                    Ретроспективный объединенный анализ данных 236 пациентов с
                    F3, принимавших
                    <br /> режим СОФ/ВЕЛ 12 недель в клинических исследованиях
                    ASTRAL-1, 2, 3, POLARIS-2, 3
                </TitleStyled>
                <Screen31Svg />
            </Graphic>
        </Container>
    );
};

export default Screen31;
