import styled from 'styled-components/macro';

export const SvgContainer = styled.div`
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    margin: auto;
    align-items: center;
    //height: 55rem;
    z-index: ${(props) => (props.zIndex ? props.zIndex : 'auto')};
    border-radius: 15px;
    padding: 10rem 2em;
`;

export const Title = styled.div`
    margin-top: 50px;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: var(--blue);
`;
