import styled from 'styled-components/macro';
import bg from '../assets/images/infobg.jpg';

export const TitleContainerStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const TitleStyled = styled.h2`
    font-family: Seravek, sans-serif;
    justify-self: center;
    align-self: center;
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    color: var(--blue);
`;

export const GraphicContainer = styled.div`
    background-image: url(${bg});
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    background-repeat: no-repeat;
    background-size: cover;

    svg {
        width: 100%;
        height: 100%;
    }
`;

export const InstructionContainerStyled = styled.div`
    box-shadow: 0em -2em 20px 20px #ecf6f3;
`;
