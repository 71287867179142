import React, { useState } from 'react';
import { Container } from '../Screen6/Screen6.styles';
import { ReactComponent as Screen25Svg } from './../../assets/images/svg/Screen25.svg';
import { ReactComponent as Screen25PSvg } from './../../assets/images/svg/Screen25-p.svg';
import { Popup } from '../../components/Popup/Popup';
import { Graphic } from '../../components/Graphic/Graphic';
import { IScreen } from '../../storage/types/app.types';
import { TitleStyled } from '../main.page.styles';

const Screen24: React.FC<IScreen> = ({ modalActive, setModalActive }) => {
    const [activeInfo, setActiveInfo] = useState<number>(0);

    const Info1 = (
        <Graphic marginTop={'0'} style={{ paddingBottom: 0 }}>
            <h2 style={{ fontSize: 30, lineHeight: '32px', fontWeight: 700 }}>
                Характеристики пациентов исследований ASTRAL-1, ASTRAL-2,
                ASTRAL-3, POLARIS-2 и POLARIS-3
            </h2>
            <Screen25PSvg style={{ width: '66rem' }} />
        </Graphic>
    );

    const infoContent: JSX.Element[] = [Info1];

    return (
        <Container style={{ zIndex: 8000, overflow: 'auto', overflowX: 'hidden' }}>
            {modalActive
                ? infoContent[activeInfo] && (
                      <Popup
                          active={modalActive}
                          setActive={setModalActive}
                          fullScreen
                          hideClose
                      >
                          {infoContent[activeInfo]}
                      </Popup>
                  )
                : null}

            <Graphic
                marginTop={`${0}`}
                style={{
                    overflow: 'auto',
                    marginTop: '50px',
                }}
            >
                <TitleStyled style={{ marginTop: '20px', fontSize: '20px' }}>
                    Ретроспективный интегрированный анализ данных 887 пациентов{' '}
                    <br /> с F0-F2, принимавших участие в клинических
                    исследованиях <br /> ASTRAL-1, ASTRAL-2, ASTRAL-3, POLARIS-2
                    и POLARIS-3
                </TitleStyled>
                <Screen25Svg />
            </Graphic>
        </Container>
    );
};

export default Screen24;
