import Screen15 from '../../../assets/images/svg/Screen15.png';
import React from 'react';
import { Container } from '../../../pages/Main/main.page.styles';

export const Screen12Info: React.FC = () => {
    return (
            <Container style={{ display: 'flex', flexDirection: 'column', gap: 10, alignItems: 'center' }}>
                <h2 style={{ fontSize: 18, fontWeight: 700, lineHeight: '20px', marginTop: 100 }}>
                    Дизайн ретроспективного исследование HBsAg-положительных
                    онкологических больных, получавших ETV, TDF или TAF во время
                    химиотерапии
                </h2>
                <img src={Screen15} style={{ width: '60vw' }} />
            </Container>
    );
};
